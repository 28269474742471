import { Switch } from "@ui/switch";
import { Label } from "@ui/label";
import { Button } from "@ui/button"
import { Share2Icon } from "@radix-ui/react-icons";

export default function InsertActions() {
  return (
    <div className="flex flex-row items-center gap-1">
      <Button variant="outline" size="sm">
        <Share2Icon/>
        Publish
      </Button>
      <div className="flex-auto"/>
      <Button size="sm">
        Save
      </Button>
    </div>
  );
}
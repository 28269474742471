import { generatePocketElementPart } from "../../catalog";
import { Blueprint, ConfigurationCornerModifier, ConfigurationHexCut, ConfigurationStackingBottom, Modifier, TrayLayoutOffsets, DebugHandler, Tray } from "@/types";
import * as rep from "replicad";
import * as lib from "../library";
import { elementPadding } from "../measurement";
import { getBlueprintContainerElements, getBlueprintProcketElements, roundNumber } from "@/utils";

export const type = "layout";

export type Configuration = ConfigurationStackingBottom & ConfigurationCornerModifier & ConfigurationHexCut;

export function getDefaultConfiguration(): Configuration {
  return {
    stackingBottom: false,
    cornerModifierEnable: true,
    cornerModifier: 1,
    cornerModifierLength: 4,
    hexCutEnable: false,
    hexCutTrimThreshold: 0.5,
    hexCutWall: 1.2,
    hexCutRadius: 8,
    hexCutOrientation: 0,
    hexCutOffsetX: 0,
    hexCutOffsetY: 0,
  };
}

export function getLayoutOffsets(tray: Blueprint): TrayLayoutOffsets {
  return {
    start: {
      x: tray.globals.wall,
      y: tray.globals.wall,
      z: tray.configuration.stackingBottom ? tray.globals.stackingHeight : tray.globals.wall,
    },
    extent: {
      x: tray.globals.wall,
      y: tray.globals.wall,
      z: 0,
    },
  };
}

export function generate(blueprint: Blueprint, tray: Tray, debug?: DebugHandler) {
  const c = blueprint.configuration as Configuration;
  const containers = getBlueprintContainerElements(tray, blueprint.elements, blueprint.root);
  const childs = getBlueprintProcketElements(tray, blueprint.elements, blueprint.root).map(p => ({
    part: generatePocketElementPart(p.blueprint, p.element, blueprint.globals, {
      xNegExtent: p.element.placement.xOffset,
      xPosExtent: roundNumber(tray.size.x - (p.element.placement.xOffset + p.element.placement.x)),
      yNegExtent: p.element.placement.yOffset,
      yPosExtent: roundNumber(tray.size.y - (p.element.placement.yOffset + p.element.placement.y)),
      zNegExtent: p.element.placement.zOffset,
      zPosExtent: roundNumber(tray.size.z - (p.element.placement.zOffset + p.element.placement.z)),
    }),
    blueprint: p.blueprint,
    pocket: p.element,
    parent: p.parent,
  }));
  const perimeter = lib.drawRectangle(tray.size.x, tray.size.y);
  const preserves: rep.Drawing[] = [];
  let preserveAll: rep.Shape3D;
  const pocketBounds: rep.Shape3D[] = [];
  const pocketBoundsHeights: number[] = [];
  const individualPocketBounds: rep.Shape3D[] = [];
  // gather shapes from pockets
  for (const child of childs) {
    const padding = elementPadding(child.pocket.placement, child.blueprint.layout);
    const height = child.pocket.placement.zOffset + (child.pocket.placement.zTotal ?? child.pocket.placement.z);
    if(!pocketBoundsHeights.includes(height)) {
      pocketBoundsHeights.push(height);
    }
    const perimeter = lib.drawRectangle((child.pocket.placement.xTotal ?? child.pocket.placement.x) + 2 * blueprint.globals.wall, (child.pocket.placement.yTotal ?? child.pocket.placement.y) + 2 * blueprint.globals.wall)
      .translate(child.pocket.placement.xOffset - blueprint.globals.wall - padding.xStart, child.pocket.placement.yOffset - blueprint.globals.wall - padding.yStart);
    preserves.push(perimeter);
    const bounds = perimeter
      .sketchOnPlane("XY")
      .extrude(height) as rep.Shape3D;
    pocketBounds.push(bounds);
    debug?.("pocketBounds #" + child.blueprint.id, bounds);
  }
  // gather shapes from container fillers
  for (const container of containers) {
    if (container.element.placement.fillers !== undefined) {
      for (const filler of container.element.placement.fillers) {
        const perimeter = lib.drawRectangle(filler.x + 2 * blueprint.globals.wall, filler.y + 2 * blueprint.globals.wall)
          .translate(filler.xOffset - blueprint.globals.wall, filler.yOffset - blueprint.globals.wall);
        const bounds = perimeter
          .sketchOnPlane("XY")
          .extrude(filler.zOffset + filler.z) as rep.Shape3D;
        pocketBounds.push(bounds);
        preserves.push(perimeter);
        debug?.("fillerBounds #" + container.id + "/" + container.element.placement.fillers.indexOf(filler), bounds);
      }
    }
  }
  let body = lib.fuseAllShapes(pocketBounds);
  debug?.("bounds", body);
  if(pocketBoundsHeights.length > 1) {
    let slices: rep.Shape3D[] = [];
    let lastHeight = 0;
    for(const height of pocketBoundsHeights.sort((a, b) => a - b)) {
      const slice = body.intersect(perimeter.sketchOnPlane("XY", lastHeight).extrude(height - lastHeight)) as rep.Shape3D;
      slices.push(slice);
      lastHeight = height;
      debug?.("boundsSlice " + height, slice);
    }
    slices = slices.map(s => {
      if (c.cornerModifierEnable) {
        if (c.cornerModifier == Modifier.Chamfer) {
          return s.chamfer(c.cornerModifierLength, e => e.inDirection([0, 0, 1]));
        } else {
          return s.fillet(c.cornerModifierLength, e => e.inDirection([0, 0, 1]));
        }
      }
      return s;
    });
    body = lib.fuseAllShapes(slices);
  } else {
    if (c.cornerModifierEnable) {
      if (c.cornerModifier == Modifier.Chamfer) {
        body = body.chamfer(c.cornerModifierLength, e => e.inDirection([0, 0, 1]));
      } else {
        body = body.fillet(c.cornerModifierLength, e => e.inDirection([0, 0, 1]));
      }
    }
  }
  debug?.("modifiedBounds", body);
  if (individualPocketBounds.length > 0) {
    let postShape = lib.fuseAllShapes(individualPocketBounds).simplify();
    if (c.cornerModifierEnable) {
      if (c.cornerModifier == Modifier.Chamfer) {
        postShape = postShape.chamfer(c.cornerModifierLength, e => e.inDirection([0, 0, 1]));
      } else {
        postShape = postShape.fillet(c.cornerModifierLength, e => e.inDirection([0, 0, 1]));
      }
    }
    debug?.("modifiedIndividualPocketBounds", postShape);
    body = body.fuse(postShape);
    debug?.("individualBounds", body);
  }
  // cut ports from shape
  for (const port of tray.ports) {
    const shapes: rep.Shape3D[] = [];
    for(const segment of port.segments) {
      shapes.push(lib.drawRectangle(segment.x, segment.y)
      .translate(port.xOffset + segment.xOffset, port.yOffset + segment.yOffset)
      .sketchOnPlane("XY", port.zOffset - blueprint.globals.stackingHeight)
      .extrude(blueprint.globals.stackingHeight) as rep.Shape3D);
    }
    let cutout = lib.fuseAllShapes(shapes);
    debug?.("stackingPort #" + port.id, cutout);
    if (c.cornerModifier == Modifier.Chamfer) {
      cutout = cutout.chamfer(c.cornerModifierLength, e => e.inDirection([0, 0, 1]));
    } else {
      cutout = cutout.fillet(c.cornerModifierLength, e => e.inDirection([0, 0, 1]));
    }
    cutout = cutout.chamfer(blueprint.globals.stackingHeight - 0.0001, e => e.inPlane("XY", port.zOffset - blueprint.globals.stackingHeight));
    debug?.("modifiedStackingPort #" + port.id, cutout);
    body = body.cut(cutout);
  }
  debug?.("ports", body);
  if(c.hexCutEnable) {
    const fusedPreserves = lib.fuseAllDrawings(preserves);
    preserveAll = fusedPreserves.sketchOnPlane("XY", -blueprint.globals.wall).extrude(tray.size.z + blueprint.globals.wall) as rep.Shape3D;
    if (c.cornerModifierEnable) {
      if (c.cornerModifier == Modifier.Chamfer) {
        preserveAll = preserveAll.chamfer(c.cornerModifierLength, e => e.inDirection([0, 0, 1]));
      } else {
        preserveAll = preserveAll.fillet(c.cornerModifierLength, e => e.inDirection([0, 0, 1]));
      }
    }
    debug?.("preserve", preserveAll);
    preserveAll = preserveAll.shell(blueprint.globals.wall, f => f.inPlane("XY", tray.size.z));
    preserveAll = preserveAll.cut(perimeter.sketchOnPlane("XY").extrude(-blueprint.globals.wall) as rep.Shape3D);
    debug?.("perimeterPreserve", preserveAll);
  }
  let cutAll = new rep.Drawing();
  let useCut = false;
  for (const child of childs) {
    if (child.part === null) {
      continue;
    }
    if (child.part.cutout !== undefined) {
      const cutout = child.part.cutout.translate(child.pocket.placement.xOffset, child.pocket.placement.yOffset, child.pocket.placement.zOffset);
      debug?.("pocketCut #" + child.blueprint.id, cutout);
      body = body.cut(cutout);
    }
    if (child.part.shape !== undefined) {
      const shape = child.part.shape.translate(child.pocket.placement.xOffset, child.pocket.placement.yOffset, child.pocket.placement.zOffset);
      debug?.("pocketShape #" + child.blueprint.id, shape);
      body = body.fuse(shape);
    }
    if (child.part.preserveAll !== undefined && c.hexCutEnable) {
      const preserve = child.part.preserveAll.translate(child.pocket.placement.xOffset, child.pocket.placement.yOffset);
      debug?.("pocketPreserve #" + child.blueprint.id, preserve);
      preserveAll = preserveAll!.fuse(preserve.sketchOnPlane("XY").extrude(tray.size.z) as rep.Shape3D);
    }
    if (child.part.cutAll !== undefined) {
      const cut = child.part.cutAll.translate(child.pocket.placement.xOffset, child.pocket.placement.yOffset);
      debug?.("pocketCutAll #" + child.blueprint.id, cut);
      cutAll = cutAll.fuse(cut);
      useCut = true;
    }
  }
  debug?.("pockets", body);
  if (c.stackingBottom) {
    body = body.chamfer(blueprint.globals.stackingHeight - 0.0001, e => e.inPlane("XY", 0))
    debug?.("bottomStacking", body);
  }
  // apply cut
  if (useCut) {
    const tool = cutAll
      .sketchOnPlane("XY")
      .extrude(tray.size.z) as rep.Shape3D;
    debug?.("cutAll", tool);
    body = body.cut(tool);
    debug?.("cut", body);
  }
  if (c.hexCutEnable) {
    debug?.("preserveAll", preserveAll!);
    const grid = lib.hexGrid(c.hexCutRadius, c.hexCutWall, tray.size.x, tray.size.y, c.hexCutOffsetX, c.hexCutOffsetY, c.hexCutOrientation == 0)
      .translate(tray.size.x / 2, tray.size.y / 2);
    debug?.("hexGrid", grid);
    /*const tinyBlueprints = ((<any>grid).innerShape as rep.Blueprints).blueprints.filter(b => b.boundingBox.width < c.hct || b.boundingBox.height < c.hct);
    tinyBlueprints.forEach(t => grid = grid.cut(t.));*/
    let gridShape = grid
    .sketchOnPlane("XY")
    .extrude(tray.size.z) as rep.Shape3D;
    gridShape = gridShape.cut(preserveAll!);
    debug?.("preservedHexGrid", gridShape);
    // @ts-expect-error using private members to find tiny subshapes
    const subShapes = gridShape._listTopo("solid").map(s => new rep.Solid(s));
    for(const subShape of subShapes) {
      const bounds = subShape.boundingBox;
      if(bounds.width < c.hexCutTrimThreshold || bounds.depth < c.hexCutTrimThreshold) {
        gridShape = gridShape.cut(subShape);
      }
    }
    debug?.("cleanedHexGrid", gridShape);
    body = body.cut(gridShape);
    debug?.("reduced", body);
  }
  for (const pocket of childs) {
    if (pocket.part === null || pocket.part.post === undefined) {
      continue;
    }
    try {
      body = pocket.part.post(body);
    } catch (e) {
      console.error("failed to postprocess tray shape: " + e);
    }
  }
  return [{
    name: "tray",
    shape: body
  }];
}
import * as React from "react"
import { Button } from "./button"
import { Input } from "./input";
import { MagicWandIcon, MinusIcon, PlusIcon, ResetIcon } from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";
import { MagicAction } from "@/types";

export interface InputNumberProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  validate?: (value: number) => true | string,
  value: number | undefined,
  setValue: (value: number) => void,
  unit?: string,
  min?: number,
  max?: number,
  change?: number,
  magic?: MagicAction,
}

const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  ({ className, value, unit, min, max, magic, change = 1, setValue, ...props }, ref) => {
    const updateValue = (value: string | number) => {
      if (typeof value === "string") {
        if (value == "") {
          return;
        }
        value = parseFloat(value);
      }
      if (min !== undefined) {
        if (value < min) {
          value = min;
        }
      }
      if (max !== undefined) {
        if (value > max) {
          value = max;
        }
      }
      setValue(value);
    };
    const preventDecrease = value === undefined || (min !== undefined && value <= min);
    const preventIncrease = value === undefined || (max !== undefined && value >= max);
    const id = React.useId();
    return (
      <div className={className}>
        <div className="flex w-full items-center gap-0.5">
          <div className="relative w-full">
            <Input id={id} type="number" ref={ref} value={value} onChange={e => updateValue(e.target.value)} className="rounded-e-none" {...props} />
            {unit !== undefined && (
              <div className="pointer-events-none absolute inset-y-0 end-0 z-20 flex items-center pe-2">
                <div className="select-none text-gray-500">
                  {unit}
                </div>
              </div>
            )}
          </div>
          {magic !== undefined && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button onClick={() => {
                  const value = magic.handler();
                  if (value !== null) {
                    updateValue(value);
                  }
                }} disabled={preventDecrease} variant="outline" className="size-8 flex-none rounded-none p-0 text-lg">
                  {magic.icon === "reset" ? <ResetIcon /> : <MagicWandIcon />}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                {magic.tooltip}
              </TooltipContent>
            </Tooltip>
          )}
          <Button onClick={() => {
            const floor = Math.floor(value!);
            if (floor !== value!) {
              updateValue(floor);
            } else {
              updateValue(value! - change);
            }
          }} disabled={preventDecrease} variant="outline" className="size-8 flex-none rounded-none p-0 text-lg">
            <MinusIcon />
          </Button>
          <Button onClick={() => {
            const ceil = Math.ceil(value!);
            if (ceil !== value!) {
              updateValue(ceil);
            } else {
              updateValue(value! + change);
            }
          }} disabled={preventIncrease} variant="outline" className="size-8 flex-none rounded-e-lg rounded-s-none border p-0 text-lg">
            <PlusIcon />
          </Button>
        </div>
      </div>
    )
  }
)
InputNumber.displayName = "InputNumber"

export { InputNumber }

/* eslint-disable react/no-unknown-property */
import Scene from "@/pages/designer/canvas/Scene";
import { Grid, Text } from "@react-three/drei";
import BoxInfo from "../canvas/BoxInfo";
import BoxFootprint from "../canvas/BoxFootprint";
import { getBlueprint, selectCurrentTrayId, useAppDispatch, useAppSelector } from "@/state/store";
import Canvas from "../canvas/Canvas";
import InsertCanvasTray from "./InsertCanvasTray";
import { Euler, Vector3 } from "three";
import InsertCanvasManipulator from "./InsertCanvasManipulator";
import { select } from "@/state/model";
import { useState } from "react";
import { Label } from "@ui/label";
import { getTrayName } from "@/utils";
import DesignerMessagesBar from "../DesignerMessagesBar";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@ui/accordion";
import BlueprintMessages from "../blueprint/BlueprintMessages";
import { CheckCircledIcon, CrossCircledIcon, ExclamationTriangleIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import DesignerMarkup from "../DesignerMarkup";

function InsertCanvasBox() {
  const insertConfiguration = useAppSelector(s => s.model.insert.configuration);
  return (
    <>
      <BoxFootprint position={{ x: 0, y: 0, z: 0 }} size={insertConfiguration} />
      <BoxInfo position={{ x: 0, y: 0, z: 0 }} size={insertConfiguration} showCoordinates={false} color="#94a3b8"/>
      <Text position={[1, 0, 1]} rotation={[Math.PI / 2, 0, 0]} scale={10} anchorX="left" anchorY="bottom">
        <meshBasicMaterial color="#94a3b8" transparent={true} opacity={0.5}/>
        Front
      </Text>
      <Text position={[insertConfiguration.x, 1, 1]} rotation={[Math.PI / 2, Math.PI / 2, 0 ]} scale={10} anchorX="left" anchorY="bottom">
        <meshBasicMaterial color="#94a3b8" transparent={true} opacity={0.5}/>
        Right
      </Text>
      <Text position={[insertConfiguration.x - 1, insertConfiguration.y, 1]} rotation={[Math.PI / 2, Math.PI, 0 ]} scale={10} anchorX="left" anchorY="bottom">
        <meshBasicMaterial color="#94a3b8" transparent={true} opacity={0.5}/>
        Back
      </Text>
      <Text position={[0, insertConfiguration.y - 1, 1]} rotation={[Math.PI / 2, -Math.PI / 2, 0 ]} scale={10} anchorX="left" anchorY="bottom">
        <meshBasicMaterial color="#94a3b8" transparent={true} opacity={0.5}/>
        Left
      </Text>
    </>
  );
}

function InsertCanvasTrayInstances() {
  const insertConfiguration = useAppSelector(s => s.model.insert.configuration);
  const trayInstances = useAppSelector(s => s.model.insert.trays);
  const dispatch = useAppDispatch();
  const selectedTrayInstance = useAppSelector(selectCurrentTrayId);
  const otherTrayInstances = trayInstances.filter(t => t !== selectedTrayInstance);
  const [manipulatorPosition, setManupulatorPosition] = useState(new Vector3());
  const [manupulatorRotation, setManipulatorRotation] = useState(new Euler());
  return (
    <>
      {selectedTrayInstance !== null && (
        <InsertCanvasManipulator position={manipulatorPosition} setPosition={setManupulatorPosition} rotation={manupulatorRotation} setRotation={setManipulatorRotation} selectedTray={selectedTrayInstance} otherTrays={otherTrayInstances} insertBounds={insertConfiguration}/>
      )}
      {trayInstances.map(i => (
        <InsertCanvasTray key={i} trayId={i} positionOverride={i == selectedTrayInstance ? manipulatorPosition : undefined} setSelectedTrayId={i == selectedTrayInstance ? undefined : id => dispatch(select({
          scope: "insert",
          aspect: "tray",
          trayId: id,
        }))}/>
      ))}
    </>
  );
}

function InsertCanvasMessages() {
  const messages = useAppSelector(s => s.model.insert.messages); 
  let infoCount = 0;
  let warningCount = 0;
  let errorCount = 0;
  for(const message of messages) {
    if(message.type === "info") {
      infoCount++;
    } else if(message.type === "warning") {
      warningCount++;
    } else {
      errorCount++;
    }
  }
  return (
    <div className="flex flex-col gap-2">
      {messages.length == 0 ? (
        <div className="flex items-center gap-2 text-sm">
          <CheckCircledIcon className="text-primary"/>
          No issues detected.
        </div>
      ) : (
        <>
        <div className="my-[-8px] flex items-center justify-between gap-2">
          <Label className="py-2">Insert validation</Label>
          <DesignerMessagesBar errorCount={errorCount} warningCount={warningCount} infoCount={infoCount}/>
        </div>
          {messages.map(m => 
            <div key={messages.indexOf(m)} className="flex items-center gap-2">
              {m.type === "warning" ? <ExclamationTriangleIcon className="text-yellow-500"/> : (m.type === "error" ? <CrossCircledIcon className="text-red-500"/> : <InfoCircledIcon/>)}
              <DesignerMarkup className="text-sm" markup={m.content}/>
          </div>
          )}
      </>
    )}
    </div>
  )
}

export default function InsertCanvas() {
  return (
    <>
    <Canvas>
      <Grid cellSize={1} cellThickness={0.6} sectionSize={10} cellColor="#F0F0F0" sectionColor="#F0F0F0" infiniteGrid={true} position={[0, 0, -0.1]} rotation={[Math.PI / 2, 0, 0]} fadeDistance={2000} fadeStrength={6} followCamera={true}/>
      <Scene panHorizontal={true}>
        <InsertCanvasTrayInstances/>
        <InsertCanvasBox/>
      </Scene>
    </Canvas>
      <div className="absolute right-1 top-1 z-50 flex select-none flex-col items-end gap-1">
        <div className="flex flex-col gap-2 rounded-sm border bg-white p-2">
          <InsertCanvasMessages/>
        </div>
      </div>
    </>
  );
}
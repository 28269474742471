import { Button } from "@ui/button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectSeparator, SelectTrigger, SelectValue } from "@ui/select";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { restoreRecord } from "@/state/model";
import { useHotkeys } from 'react-hotkeys-hook'
import HistoryChange from "./HistoryChange";
import { ArrowUUpLeft, ArrowUUpRight } from "@phosphor-icons/react";

export default function DesignerHistoryToolbar() {
  const history = useAppSelector(s => s.model.history);
  const dispatch = useAppDispatch();
  useHotkeys("ctrl+z,ctrl+y", (e) => dispatch(restoreRecord({
    index: e.key == "z" ? history.index - 1 : history.index + 1,
  })));
  return (
    <>
      <Button size="sm" disabled={history.index <= 0} variant="outline" onClick={() => {
        dispatch(restoreRecord({
          index: history.index - 1,
        }));
      }}>
        <ArrowUUpLeft/>
        Undo
      </Button>
      <Button size="sm" disabled={history.index >= history.records.length - 1} variant="outline" onClick={() => {
        dispatch(restoreRecord({
          index: history.index + 1,
        }));
      }}>
        <ArrowUUpRight/>
        Redo
      </Button>
      <Select value={history.index.toString()} onValueChange={e => {
        dispatch(restoreRecord({
          index: parseInt(e),
        }));
      }}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {history.records.slice(0, history.index + 1).map(r => {
            const index = history.records.indexOf(r);
            return (
              <SelectItem value={index.toString()} key={index}>
                <HistoryChange change={r.change} type={r.type} inactive={false}/>
              </SelectItem>
            );
          })}
          {history.index + 1 < history.records.length && (
            <>
              <SelectSeparator/>
              <SelectGroup>
                <SelectLabel className="font-normal">
                  <span className="font-semibold">Current timeline</span>, making changes will start a new timeline and discard the last
                  </SelectLabel>
                
              {history.records.slice(history.index + 1).map(r => {
                const index = history.records.indexOf(r);
                return (
                  <SelectItem value={index.toString()} key={index}>
                    <HistoryChange change={r.change} type={r.type} inactive={true}/>
                  </SelectItem>
                );
              })}
              </SelectGroup>
            </>
          )}
        </SelectContent>
      </Select>
    </>
  );
}
import { InputSubmit } from "@/components/ui/inputSubmit";
import ConfigurationEditor from "../ConfigurationEditor";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { modifyInsert } from "@/state/model";
import { Drawer } from "@/types";
import { useMemo } from "react";

export function InsertBasics({ query }: { query: string }) {
  const dispatch = useAppDispatch();
  const name = useAppSelector(s => s.model.insert.name);
  const path = useAppSelector(s => s.model.insert.path);
  const drawers = useMemo<Drawer[]>(() => [{
    keys: [ "insertName" ],
    control:  <InputSubmit id="name" initialValue={name} applyValue={n => dispatch(modifyInsert({
      name: n,
    }))} />,
    useLabel: true,
  }, {
    keys: [ "insertPath" ],
    control: <InputSubmit id="path" initialValue={path} applyValue={n => dispatch(modifyInsert({
      path: n,
    }))} preprocess={v => {
      if(!v.startsWith("/")) {
        v = "/" + v;
      }
      return v;
    }} />,
    useLabel: true,
  }], [ name, path ]);
  return (
    <ConfigurationEditor query={query} configuration={{}} modifyConfiguration={c => { }} drawers={drawers}/>
  );
}
import InsertsList from "@/pages/community/InsertsList";
import { Link } from "react-router-dom";
import { Button } from "@ui/button";

export default function Home() {
  return (
    <div className="flex flex-col">
      <div className="p-2">
        <InsertsList />
      </div>
      {window.location.hostname}
      <Link to="/insert">
        <Button>
          Open insert
        </Button>
      </Link>
      <div className="h-10 w-96 bg-[#be123c]"/>
      <div className="h-10 w-96 bg-[#0891b2]"/>
      <div className="h-10 w-96 bg-[#6d28d9]"/>
      <div className="h-10 w-96 bg-[#ea580c]"/>
      <div className="h-10 w-96 bg-[#65a30d]"/>
      <div className="h-10 w-96 bg-[#ca8a04]"/>
      <div className="h-10 w-96 bg-[#c026d3]"/>
      <div className="h-10 w-96 bg-[#0d9488]"/>
      <div className="h-10 w-96 bg-[#d97706]"/>
      <div className="h-10 w-96 bg-[#15803d]"/>
      <div className="h-10 w-96 bg-[#2563eb]"/>
      <div className="h-10 w-96 bg-[#e11d48]"/>
      <div className="h-10 w-96 bg-[#0e7490]"/>
      <div className="h-10 w-96 bg-[#a21caf]"/>
      <div className="h-10 w-96 bg-[#047857]"/>
    </div>
  );
}
import { Checkbox } from "@ui/checkbox"
import { configurationKeyDescription, configurationKeyName, configurationGroup, configurationKeyEnumerations } from "../../catalog";
import { InputNumber } from "@ui/inputNumber";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@ui/select";
import TrayConfigurationLabel from "./ConfigurationLabel";
import { Configuration, Drawer, MagicActions } from "@/types";
import getCommonDrawers from "./ConfigurationEditorDrawers";

export default function ConfigurationEditor({ query, configuration, modifyConfiguration, drawers, magicActions }: {
  query: string,
  configuration: Configuration,
  modifyConfiguration: (configuration: Configuration) => void,
  drawers?: Drawer[],
  magicActions?: MagicActions,
}) {
  const items: JSX.Element[] = [];
  if (drawers === undefined) {
    drawers = [];
  }
  if (magicActions === undefined) {
    magicActions = {};
  }
  // get all keys
  let keys = Object.keys(configuration).concat(...drawers.reduce<string[]>((a, b) => a.concat(...b.keys), [])).sort((a, b) => {
    const ga = configurationGroup(a);
    const gb = configurationGroup(b);
    if (ga === undefined && gb === undefined) {
      return 0;
    } else if (ga !== undefined && gb !== undefined) {
      return ga.localeCompare(gb);
    } else if (ga !== undefined) {
      return 1;
    } else {
      return -1;
    }
  });
  // allways use common drawers but don't add their keys
  drawers = drawers.concat(...getCommonDrawers());
  const handledGroups: string[] = [];
  while (keys.length > 0) {
    const key = keys.splice(0, 1)[0];
    let name = configurationKeyName(key);
    let description = configurationKeyDescription(key);
    const group = configurationGroup(key);
    if (query !== "" && !name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) && (group === undefined || !group.toLocaleLowerCase().includes(query.toLocaleLowerCase())) && (description === undefined || !description.toLocaleLowerCase().includes(query.toLocaleLowerCase()))) {
      continue;
    }
    if (group !== undefined && !handledGroups.includes(group)) {
      handledGroups.push(group);
      items.push(
        <tr key={group}>
          <td colSpan={2}>
            <div className="border-b-2 border-slate-200 pl-[9px]" style={{
              marginLeft: -9,
              marginRight: -9,
            }}>
              <div className="py-1 text-sm font-medium leading-none text-primary">
                {group}
              </div>
            </div>
          </td>
        </tr>
      )
    }
    const drawer = drawers.find(d => d.keys.includes(key));
    if (drawer !== undefined) {
      // remove all keys of drawer
      keys = keys.filter(k => !drawer.keys.includes(k));
      if(drawer.keys.length > 0) {
        name = configurationKeyName(drawer.keys[0]);
        description = configurationKeyDescription(drawer.keys[0]);
      }
      if (drawer.useLabel) {
        items.push((
          <tr key={key}>
            <td>
              <TrayConfigurationLabel id={key} name={name} description={description} />
            </td>
            <td>
              { typeof drawer.control === "function" ? drawer.control(configuration, modifyConfiguration) : drawer.control }
            </td>
          </tr>
        ));
      } else {
        items.push((
          <tr key={key}>
            <td colSpan={2}>
              { typeof drawer.control === "function" ? drawer.control(configuration, modifyConfiguration) : drawer.control }
            </td>
          </tr>
        ));
      }
      continue;
    }
    const value = configuration[key];
    const enumerations = configurationKeyEnumerations(key);
    if (typeof value === "boolean") {
      items.push((
        <tr key={key}>
          <td colSpan={2}>
            <div className="flex items-center gap-1 py-1">
              <Checkbox id={key} checked={value} onCheckedChange={e => {
                const data: Configuration = {};
                data[key as string] = Boolean(e);
                modifyConfiguration(data);
              }} />
              <TrayConfigurationLabel id={key} name={name} description={description} />
            </div>
          </td>
        </tr>
      ));
    } else if (typeof value === "number" && enumerations !== undefined) {
      items.push((
        <tr key={key}>
          <td>
            <TrayConfigurationLabel id={key} name={name} description={description} />
          </td>
          <td>
            <Select name={key} value={enumerations[value]} onValueChange={e => {
              const index = enumerations.indexOf(e);
              if (index !== -1) {
                const data: Configuration = {};
                data[key as string] = index;
                modifyConfiguration(data);
              }
            }}>
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {enumerations.map(e => (
                  <SelectItem value={e} key={e}>
                    {e}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </td>
        </tr>
      ));
    } else if (typeof value === "number") {
      if (key.endsWith("Percent")) {
        items.push((
          <tr key={key}>
            <td>
              <TrayConfigurationLabel id={key} name={name} description={description} />
            </td>
            <td>
              <InputNumber unit="%" id={key} min={0} max={100} change={10} value={value} magic={magicActions[key]} setValue={e => {
                const data: Configuration = {};
                data[key as string] = e;
                modifyConfiguration(data);
              }} />
            </td>
          </tr>
        ));
      } else {
        items.push((
          <tr key={key}>
            <td>
              <TrayConfigurationLabel id={key} name={name} description={description} />
            </td>
            <td>
              <InputNumber unit="mm" id={key} value={value} magic={magicActions[key]} setValue={e => {
                const data: Configuration = {};
                data[key as string] = e;
                modifyConfiguration(data);
              }} />
            </td>
          </tr>
        ));
      }
    }
  }
  return (
    <table className="table w-full table-auto border-separate">
      <tbody>
        {items}
      </tbody>
    </table>
  );
}
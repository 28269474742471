import * as React from "react"
import { cn } from "./utils"
import { Button } from "./button"
import { Input } from "./input";
import { Cross2Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

export interface InputSearchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onClear?: () => void;
  inputClassName?: string,
}

const InputSearch = React.forwardRef<HTMLInputElement, InputSearchProps>(
  ({ className, inputClassName, onClear, ...props }, ref) => {
    const input = React.useRef<HTMLInputElement>(null);
    ref = input;
    return (
      <div className={cn("relative", className)}>
        <Input className={cn("flex-auto pl-7", inputClassName)} ref={input} {...props} onKeyDown={k => {
          if (k.code === "Escape" && onClear !== undefined) {
            onClear();
          }
        }} />
        <div className="pointer-events-none absolute inset-y-0 start-0 z-20 flex items-center pl-2 opacity-50">
          <MagnifyingGlassIcon />
        </div>
        {onClear !== undefined && (
          <div className="absolute inset-y-[1px] end-[1px] z-20 flex items-center">
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" className="px-3" onClick={() => onClear()}>
                  <Cross2Icon />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                Press <b>Esc</b> or use this button to clear the search query.
              </TooltipContent>
            </Tooltip>
          </div>
        )}
      </div>
    )
  }
)
InputSearch.displayName = "InputSearch"

export { InputSearch }

import * as rep from "replicad";
import { InsertGlobals, Part, PocketElementBlueprint, TrayMeasure, ConfigurationCornerModifier, ConfigurationHexCutPrevent, ConfigurationShell, ConfigurationX, ConfigurationY, PocketEntityGenerationOptions, TrayElement } from "@/types";
import * as lib from "../library";

export const type = "tokens-shell";

export type Configuration = ConfigurationX & ConfigurationY & ConfigurationCornerModifier & ConfigurationShell & ConfigurationHexCutPrevent;

export function getDefaultConfiguration() : Configuration  {
  return {
    x: 40,
    y: 60,
    shell: 1.2,
    cornerModifierEnable: true,
    cornerModifier: 1,
    cornerModifierLength: 4,
    hexCutPrevent: true,
  };
}

export function measureElement(c: Configuration, globals: InsertGlobals) {
  const m: TrayMeasure = {
    xMin: c.x,
    yMin: c.y,
    zMin: 0, // always use requested size
  };
  return m;
}

export function generatePart(blueprint: PocketElementBlueprint, element: TrayElement, globals: InsertGlobals, options: PocketEntityGenerationOptions): Part {
  const c = <unknown>blueprint.configuration as Configuration;
  const p = element.placement;
  // p.x == x.c and so on, this is a simple case where the outer dimensions equal the configuration
  let drawing = lib.drawRectangle(p.x, p.y);
  if(c.cornerModifierEnable) {
    if(c.cornerModifier == 0) {
      drawing = drawing.chamfer(c.cornerModifierLength);
    } else {
      drawing = drawing.fillet(c.cornerModifierLength);
    }
  }
  let preserve = drawing.offset(globals.wall);
  if(!c.hexCutPrevent) {
    preserve = preserve.cut(drawing);
  }
  const cutout = drawing
    .sketchOnPlane("XY", element.placement.zOffset + element.placement.z - 1)
    .extrude(1) as rep.Shape3D;
  return {
    cutout: cutout,
    preserveAll: preserve,
    /*post: shape => shape.shell(c.s, f => f.and([
      f => f.inPlane("XY", e.placement.oz + e.placement.z - 1), 
      f => f.containsPoint([e.placement.ox + e.placement.x / 2, e.placement.oy + e.placement.y / 2, e.placement.oz + e.placement.z - 1])
    ])),*/
    post: shape => shape.shell(c.shell, f => f.inPlane("XY", element.placement.zOffset + element.placement.z - 1)),
  };
}
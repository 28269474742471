import { ExternalLinkIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import { DbTray } from "@/state/api";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { imageUrlFromId, parseBlueprintId } from "@/utils";

export default function WorkbenchTray( { tray } : { tray: DbTray }) {
  const navigate = useNavigate();
  const coverPath = imageUrlFromId(tray.id, "c");
  const overviewPath = imageUrlFromId(tray.id, "o");
  return (
    <div className="relative size-52 rounded-sm border bg-white">
      <div className="pointer-events-none absolute inset-x-0 top-0 bg-slate-300/30 px-2 py-1">
        {tray.name}
      </div>
      <div className="pointer-events-none absolute inset-x-0 bottom-0 flex items-center p-1">
        <Badge variant="outline" className="gap-1 border-slate-300 text-black">
          <EyeOpenIcon/>
          {tray.views}
        </Badge>
        <div className="flex-auto"/>
        <Button variant="outline" className="pointer-events-auto h-6" onClick={() => {
          navigate("/designer?tray=" + tray.id);
        }}>
          <ExternalLinkIcon/>
        </Button>
      </div>
      <img className="size-52 rounded-sm object-contain p-1" src={coverPath} onMouseOver={e => {
        e.currentTarget.src = overviewPath;
      }} onMouseOut={e => {
        e.currentTarget.src = coverPath;
      }}/>
    </div>
  );
}
import { InputSubmit } from "@/components/ui/inputSubmit";
import ConfigurationEditor from "../ConfigurationEditor";
import { selectCurrentBlueprint, useAppDispatch, useAppSelector } from "@/state/store";
import { modifyBlueprint } from "@/state/model";
import { AlertDialog, AlertDialogFooter, AlertDialogHeader, AlertDialogTrigger, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogTitle } from "@ui/alert-dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@ui/select";
import { ColorPicker } from "@/components/ui/colorPicker";
import { Drawer, Blueprint } from "@/types";
import { useMemo, useState } from "react";
import { BlueprintType, blueprintTypes } from "@/catalog";

export function BlueprintBasicsProperties({ query, blueprintId }: { query: string, blueprintId: number }) {
  const dispatch = useAppDispatch();
  const name = useAppSelector(s => s.model.blueprints[blueprintId].name);
  const type = useAppSelector(s => s.model.blueprints[blueprintId].type);
  const color = useAppSelector(s => s.model.blueprints[blueprintId].color);
  const [trayTypeWarningOpen, setTrayTypeWarningOpen] = useState(false);
  const [newTrayType, setNewTrayType] = useState(type);
  const drawers = useMemo<Drawer[]>(() => [{
    keys: ["blueprintName" ],
    control: <InputSubmit applyValue={value => {
      dispatch(modifyBlueprint({
        blueprint: blueprintId,
        name: value
      }));
    }} initialValue={name} />,
    useLabel: true,
  }, {
    keys: ["trayType" ],
    control: <Select value={type} onValueChange={e => {
      if (e != type) {
        setTrayTypeWarningOpen(true);
        setNewTrayType(e as BlueprintType);
      }
    }}>
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {blueprintTypes.map(t => (
          <SelectItem value={t} key={t}>
            {t}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>,
    useLabel: true,
  }, {
    keys: ["trayColor" ],
    control: <ColorPicker color={color} onColorChange={c => dispatch(modifyBlueprint({
      blueprint: blueprintId,
      color: c,
    }))} swatches={["#f43f5e", "#ec4899", "#d946ef", "#a855f7", "#8b5cf6", "#6366f1", "#3b82f6", "#0ea5e9", "#06b6d4", "#14b8a6", "#10b981", "#22c55e", "#84cc16", "#eab308", "#f59e0b", "#f97316", "#ef4444", "#78716c", "#64748b", "#6b7280"]} />,
    useLabel: true,
  }], [ blueprintId ]);
  return (
    <>
      <AlertDialog open={trayTypeWarningOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. Changing the tray type will remove all incompatible configuration options and pockets.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => {
              setTrayTypeWarningOpen(false);
              setNewTrayType(type);
            }}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => {
              dispatch(modifyBlueprint({
                blueprint: blueprintId,
                type: newTrayType,
              }));
            }}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <ConfigurationEditor query={query} configuration={{}} modifyConfiguration={c => { }} drawers={drawers}/>
    </>
  );
}
import { CommitIcon, Cross1Icon } from "@radix-ui/react-icons";
import { useListTraysQuery } from "@/state/api";
import WorkbenchTray from "./WorkbenchTray";
import { ScrollArea } from "@ui/scroll-area";
import { InputSearch } from "@ui/inputSearch";
import { Switch } from "@ui/switch";
import { Label } from "@ui/label";

export default function WorkbenchTrays() {
  const { isFetching, isSuccess, data = [] } = useListTraysQuery({});
  if(isFetching) {
    return (
      <div className="flex gap-1">
        <CommitIcon className="animate-spin"/>
        Loading shared trays...
      </div>
    );
  } else if(isSuccess) {
    return (
      <div className="flex w-full flex-col">
        <div className="flex items-center gap-2 border-b bg-white p-2">
          <InputSearch className="flex-auto"/>
          <div className="flex items-center gap-1">
            <Switch id="center"/>
            <Label htmlFor="center">Only owned</Label>
          </div>
        </div>
        <ScrollArea className="p-2" type="auto" >
          <div className="flex flex-wrap gap-1">
            {data.map(t => <WorkbenchTray key={t.id} tray={t}/>)}
          </div>
        </ScrollArea>
      </div>
    );
  } else {
    return (
      <div className="flex gap-1">
        <Cross1Icon/>
        Failed to load shared trays.
      </div>
    );
  }
}
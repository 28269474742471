import { store } from "@/state/store";
import Designer from "../pages/designer/Designer";
import { Provider } from "react-redux";
import { TooltipProvider } from "./ui/tooltip";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ExploreLayout from "../pages/explore/ExploreLayout";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Error404 from "../pages/community/Error404";
import Home from "../pages/community/Home";
import WorkbenchLayout from "@/pages/workbench/WorkbenchLayout";
import WorkbenchRecent from "@/pages/workbench/WorkbenchRecent";
import DesignerLayout from "@/pages/designer/DesignerLayout";
import WorkbenchAll from "@/pages/workbench/WorkbenchAll";
import WorkbenchTrays from "@/pages/workbench/WorkbenchTrays";
import { IconContext } from "@phosphor-icons/react";
import { StrictMode } from "react";

export default function ReplicadApp() {
  return (
    <StrictMode>
      <GoogleOAuthProvider clientId="653501001383-gui3o1ksajqg2m366l850dg886to2mcr.apps.googleusercontent.com">
        <TooltipProvider>
          <Provider store={store}>
            <IconContext.Provider value={{
              color: "current",
              weight: "regular",
              mirrored: false,
              className: "flex-none size-4"
            }}> 
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<ExploreLayout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="insert" element={<Designer/>} />
                    <Route path="profile" element={<Designer/>} />
                    <Route path="profile" element={<Designer/>} />
                    <Route path="*" element={<Error404/>} />
                  </Route>
                  <Route path="/workbench" element={<WorkbenchLayout/>}>
                    <Route index element={<WorkbenchRecent/>}/>
                    <Route path="recent" element={<WorkbenchRecent/>} />
                    <Route path="all" element={<WorkbenchAll/>} />
                    <Route path="*" element={<Error404/>} />
                    <Route path="trays" element={<WorkbenchTrays/>} />
                  </Route>
                  <Route path="/designer" element={<DesignerLayout/>}>
                    <Route index element={<Designer/>}/>
                  </Route>
                </Routes>
              </BrowserRouter>
            </IconContext.Provider>
          </Provider>
        </TooltipProvider>
      </GoogleOAuthProvider>
    </StrictMode>
  );
}
import { UpdateIcon } from "@radix-ui/react-icons";
import { useGetInsertsQuery } from "../../state/api";
import InsertItem from "./InsertItem";

export default function InsertsList() {
  const { isFetching, isSuccess, error, data } = useGetInsertsQuery({});
  if(isFetching) {
    return <div className="animate-spin"><UpdateIcon/></div>
  } else if(isSuccess) {
    return data.map(d => <InsertItem key={d.id} insert={d}/>);
  }
}
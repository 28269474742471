import { Label } from "@ui/label";
import { Button } from "@ui/button"
import { selectFromBlueprint, useAppDispatch, useAppSelector, } from "@/state/store";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@ui/dialog";
import { Input } from "@ui/input";
import { Badge } from "@ui/badge";
import { useRef } from "react";
import { select } from "@/state/model";
import { ArrowLeftIcon, TokensIcon } from "@radix-ui/react-icons";
import BlueprintShare from "./BlueprintShare";
import { TrayModel } from "@/types";

export default function BlueprintActions({ blueprintId, models, trayId }: { blueprintId: number, trayId: number, models: TrayModel[] }) {
  const dispatch = useAppDispatch();
  const version = useAppSelector(s => s.model.blueprints[blueprintId].version);
  const library = useAppSelector(s => s.model.blueprints[blueprintId].library);
  const input = useRef<HTMLInputElement>(null);
  return (
    <div className="flex flex-row items-center gap-1">
      <Dialog>
        <DialogTrigger asChild>
          <Button variant="outline">Save</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Save current tray</DialogTitle>
            <DialogDescription>
              Save the current tray as <Badge variant="outline" className="px-1">version {version}</Badge> and clear all tracked changes. Further changes are compared with the newly created version.
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-1">
            <Label>Description</Label>
            <Input placeholder="optional description of changes" ref={input} />
            <Label className="mb-0 mt-2">Change summary</Label>
            {/**<TrayDiff showActions={false} />**/}
          </div>
          <DialogFooter>
            <Button>
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog>
        <DialogTrigger asChild>
          <Button variant="outline">
            <TokensIcon />
            {library === undefined ? "Add to tray library" : "Update in tray library"}
          </Button>
        </DialogTrigger>
        <DialogContent className="max-w-[1000px] max-h-[800px]">
          <BlueprintShare blueprintId={blueprintId} models={models}/>
        </DialogContent>
      </Dialog>
      <div className="flex-auto" />
      {(library === undefined || library === null || !library.isIsolated) && (
        <Button onClick={() => dispatch(select({
          scope: "insert",
          aspect: "tray",
          trayId: trayId,
        }))}>
          <ArrowLeftIcon/>
          back to insert
        </Button>
      )}
    </div>
  );
}
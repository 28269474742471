import { Link } from "react-router-dom";
import { InputSearch } from "@ui/inputSearch";
import { Button } from "@ui/button";
import { BellIcon, MixIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import NavigationMenuLogin from "./NavigationMenuLogin";

export default function NavigationMenu() {
  return (
    <div className="flex h-full items-center gap-2">
      <Link to="/" className="flex items-center gap-2">
        <MixIcon className="size-6 text-primary" />
        <div className="text-nowrap font-semibold">
          Board Game Inserts
        </div>
      </Link>
      <Link to="/">
        <Button variant="outline" className="h-8">Explore</Button>
      </Link>
      <Link to="/workbench">
        <Button variant="outline" className="h-8">Workbench</Button>
      </Link>
      <div className="flex flex-auto justify-end">
        <InputSearch id="search" className="h-8 w-full max-w-lg"/>
      </div>
      <Link to="/designer">
        <Button variant="outline" className="h-8 px-2">
          <PlusCircledIcon className="size-5"/>
        </Button>
      </Link>
      <Button variant="outline" className="h-8 px-2">
        <BellIcon className="size-5"/>
      </Button>
      <NavigationMenuLogin/>
    </div>
  );
}
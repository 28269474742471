import { QuestionCircleIcon } from "react-line-awesome";
import { Label } from "@ui/label";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";

export default function TrayConfigurationLabel({ id, name, description }: { id: string, name: string, description: string | undefined }) {
  if(description !== undefined) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex">
            <Label htmlFor={id} className="mr-1 text-nowrap">{name}</Label>
            <QuestionCircleIcon size="lg" className="mr-1" />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          {description}
        </TooltipContent>
      </Tooltip>
    );
  }
  return (
    <Label htmlFor={id} className="mr-1 text-nowrap">{name}</Label>
  );
}
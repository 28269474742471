import { useCallback, useEffect, useState } from "react";
import workerUrl from "./worker?worker&url";
import { TrayGeometry, Blueprint, Tray } from "./types";
import { calculateBlueprintHash, getTrayName } from "./utils";
import { getBlueprint, getTray } from "./state/store";

export interface WorkerRequest {
  type: "model" | "stl",
  blueprint: Blueprint,
  tray: Tray,
  debug: boolean,
}

export interface WorkerResultGeometry {
  type: "geometry",
  geometries: TrayGeometry[],
  duration: number,
}

export interface WorkerResultStl {
  type: "stl",
  stl: Blob,
  duration: number,
}

export interface WorkerResultFail {
  type: "failure",
  error: string,
  duration: number,
}

export type WorkerResult = WorkerResultStl | WorkerResultGeometry | WorkerResultFail;

export interface WorkerState {
  blueprintId: string | null,
  lastTask: string,
  lastDuration?: number,
  error?: string,
}

export interface WorkerInstance extends WorkerState {
  worker: Worker,
}

let geometryCache: { [id: string]: TrayGeometry[] } = {};
let blueprintVersion: { [id: string]: number } = {};
const workers: WorkerInstance[] = [];
const listeners: ((states: WorkerState[]) => void)[] = [];

export function addWorkers(count: number = 1) {
  while (count-- > 0) {
    workers.push({
      blueprintId: null,
      lastTask: "no recent run",
      worker: new Worker(workerUrl, {
        type: "module",
      }),
    });
  }
  update();
}

// add initial 4 workers
addWorkers(4);

export function getCacheSize() {
  return Object.keys(geometryCache).length;
}

export function clearCache() {
  geometryCache = {};
  blueprintVersion = {};
}

export function useWorkerPool() {
  const [states, updateStates] = useState<WorkerState[]>(workers);
  const update = useCallback((s: WorkerState[]) => {
    updateStates(s);
  }, []);
  useEffect(() => {
    listeners.push(update);
    return () => {
      const index = listeners.indexOf(update);
      if (index != -1) {
        listeners.splice(index, 1);
      }
    };
  }, []);
  return states;
}

function postRequest(worker: Worker, request: WorkerRequest) {
  worker.postMessage(request);
}

function update(index?: number, state?: WorkerState) {
  if (index !== undefined && state != undefined) {
    workers[index].error = state.error;
    workers[index].lastDuration = state.lastDuration;
    workers[index].lastTask = state.lastTask
    workers[index].blueprintId = state.blueprintId;
  }
  const copy: WorkerState[] = workers.map(w => ({
    lastTask: w.lastTask,
    error: w.error,
    lastDuration: w.lastDuration,
    blueprintId: w.blueprintId,
  }));
  for (const listener of listeners) {
    listener(copy);
  }
}

export function inGeometryCache(blueprintId: number, debug: boolean) {
  const id = (debug ? "d-" : "") + calculateBlueprintHash(getBlueprint(blueprintId));
  return id in geometryCache;
}

export function generateTrayGeometry(trayId: number, debug: boolean): Promise<TrayGeometry[]> {
  const tray = getTray(trayId);
  const blueprint = getBlueprint(tray.blueprintId);
  if (blueprint === null || tray === null || tray.size.x == 0 || tray.size.y == 0 || tray.size.z == 0) {
    return Promise.resolve<TrayGeometry[]>([]);
  }
  const id = blueprint.modelHash + "-" + tray.modelHash + (debug ? "-d" : "");
  let version = blueprintVersion[id];
  if (version !== undefined) {
    blueprintVersion[id] = ++version;
  } else {
    version = 1;
    blueprintVersion[id] = version;
  }
  if (id in geometryCache) {
    return Promise.resolve(geometryCache[id]);
  }
  // get worker
  let index = 0;
  for (; index < workers.length; index++) {
    if (workers[index].blueprintId === null) {
      // worker is free
      break;
    } /*else if(states[index].trayId === tray.id) {
        // worker is busy with old tray version
        workers[index].terminate();
        break;
      }*/
  }
  if (index == workers.length) {
    // todo handle full
  }
  return new Promise<TrayGeometry[]>(resolve => {
    update(index, {
      lastTask: "updating tray " + getTrayName(blueprint.name, tray.name),
      blueprintId: id,
    });
    postRequest(workers[index].worker, {
      type: "model",
      blueprint: blueprint,
      tray: tray,
      debug: debug
    });
    workers[index].worker.addEventListener("message", (e: MessageEvent<WorkerResult>) => {
      if (blueprintVersion[id] > version) {
        // newer version was already requested
        update(index, {
          lastTask: "cancelled update of tray " + getTrayName(blueprint.name, tray.name),
          blueprintId: null,
          lastDuration: e.data.duration,
        });
        resolve([]);
      }
      if (e.data.type === "failure") {
        update(index, {
          lastTask: "failed to update tray " + getTrayName(blueprint.name, tray.name),
          blueprintId: null,
          lastDuration: e.data.duration,
          error: e.data.error,
        });
        resolve([]);
      } else if (e.data.type === "geometry") {
        update(index, {
          lastTask: "updated tray " + getTrayName(blueprint.name, tray.name),
          blueprintId: null,
          lastDuration: e.data.duration,
        });
        geometryCache[id] = e.data.geometries;
        resolve(e.data.geometries);
      } else {
        update(index, {
          lastTask: "looking for magic pony " + getTrayName(blueprint.name, tray.name),
          blueprintId: null,
          lastDuration: e.data.duration,
        });
        resolve([]);
      }
    }, {
      once: true,
    });
  });
}
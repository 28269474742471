import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from './store';

export interface DbInsert {
  id: number,
  ownerId: number,
  name: string,
  description: string,
  gameName: string,
  bggId: number,
  image: string,
}

export interface DbUser {
  id: number,
  name: string,
  email: string,
  timestamp: string, 
}

export interface LoginResponse extends DbUser {
  token: string,
}

export interface AddTrayResponse {
  id: number,
}

export interface AddTrayVersionResponse {
  id: number,
  version: number,
}


export interface DbTrayDetails {
  ownerId: number,
  name: string,
  views: string,
  latestVersion: number,
  timestamp: string,
  content: string,
}

export interface DbTray {
  id: string,
  name: string,
  views: number,
  latestVersion: number,
}

export interface DbTrayVersion {
  version: number,
  timestamp: string,
  description: string,
}

export interface DbTrayVersionDetails {
  ownerId: number,
  name: string,
  views: string,
  timestamp: string,
  content: string,
}

export const insertsApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ 
    baseUrl: import.meta.env.MODE == "development" ? "http://localhost:3000" : "https://api.boardgameinserts.xyz",
    prepareHeaders: (headers, api) => {
      const token = (api.getState() as RootState).auth.token;
      if(token !== undefined) {
        headers.set("Authorization", "Baerer " + token);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getInserts: builder.query<DbInsert[], {
      ownerId?: number,
      search?: string,
    }>({
      query: (arg) => ({
        url: "/inserts",
        params: arg,
      }),
    }),
    login: builder.query<LoginResponse, {
      token: string,
    }>({
      query: (arg) => ({
        url: "/auth/login",
        params: arg,
      }),
    }),
    addTray: builder.mutation<AddTrayResponse, {
      name: string,
      description: string,
      content: string,
      images: string[],
    }>({
      query: (arg) => ({
        url: "/trays/",
        method: "POST",
        body: arg,
      }),
    }),
    addTrayVersion: builder.mutation<AddTrayVersionResponse, {
      id: string,
      description: string,
      content: string,
      coverSrc: string,
      overviewSrc: string,
    }>({
      query: (arg) => ({
        url: `/trays/${arg.id}/versions`,
        method: "POST",
        body: {
          description: arg.description,
          content: arg.content,
          coverSrc: arg.coverSrc,
          overviewSrc: arg.overviewSrc,
        },
      }),
    }),
    getTray: builder.query<DbTrayDetails, {
      id: string,
    }>({
      query: (arg) => ({
        url: `/trays/${arg.id}`,
      }),
    }),
    listTrayVersions: builder.query<DbTrayVersion[], {
      id: string,
    }>({
      query: (arg) => ({
        url: `/trays/${arg.id}/versions`,
      }),
    }),
    getTrayVersion: builder.query<DbTrayVersionDetails, {
      id: string,
      version: number,
    }>({
      query: (arg) => ({
        url: `/trays/${arg.id}/versions/${arg.version}`,
      }),
    }),
    listTrays: builder.query<DbTray[], Record<string, never>>({
      query: () => ({
        url: "/trays",
      }),
    }),
  }),
});

export const { useGetInsertsQuery, useAddTrayMutation, useAddTrayVersionMutation, useLoginQuery, useLazyLoginQuery, useLazyGetTrayQuery, useListTraysQuery } = insertsApi;
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DbUser } from './api';

export interface Auth {
  user: DbUser | undefined,
  token: string | undefined,
}

export const DefaultAuth: Auth = {
  user: undefined,
  token: undefined,
}

export const authSlice = createSlice({
  name: "auth",
  initialState: DefaultAuth,
  reducers: {
    login: (state, action: PayloadAction<{
      token: string,
      user: DbUser,
    }>) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem("token", action.payload.token);
    },
    logout: (state) => {
      state.token = undefined;
      state.user = undefined;
      localStorage.removeItem("token");
    },
  }
});


export const { login, logout } = authSlice.actions;

export default authSlice.reducer; 
import { selectCurrentElement, selectCurrentBlueprint, useAppSelector } from "@/state/store";
import { useState } from "react";
import { BlueprintBasicsProperties } from "./blueprint/BlueprintBasicsProperties";
import { BlueprintConfigurationProperties } from "./blueprint/BlueprintConfigurationProperties";
import BlueprintGlobalsProperties from "./blueprint/BlueprintGlobalsProperties";
import { PocketConfigurationProperties } from "./blueprint/element/PocketConfigurationProperties";
import { ContainerConfigurationProperties } from "./blueprint/element/ContainerConfigurationProperties";
import { InputSearch } from "@/components/ui/inputSearch";
import { ScrollArea } from "@/components/ui/scroll-area";
import { InsertBasics } from "./insert/InsertBasics";
import { ElementConfiguration } from "./blueprint/element/ElementConfiguration";
import InsertGlobalsProperties from "./insert/InsertGlobalsProperties";
import InsertConfigurationProperties from "./insert/InsertConfigurationProperties";
import InsertTrayProperties from "./insert/InsertTrayProperties";


export default function Properties({ trayId }: { trayId?: number }) {
  const selection = useAppSelector(s => s.model.selection);
  const [query, setQuery] = useState("");
  let content: JSX.Element;
  if (selection.scope === "insert") {
    if (selection.aspect === "tray") {
      content = <InsertTrayProperties query={query} />;
    } else if (selection.aspect === "basics") {
      content = <InsertBasics query={query} />;
    } else if (selection.aspect === "configuration") {
      content = <InsertConfigurationProperties query={query} />;
    } else if (selection.aspect === "globals") {
      content = <InsertGlobalsProperties query={query} />;
    } else {
      content = (
        <div className="flex items-center justify-center">
          Select something to view its properties.
        </div>
      );
    }
  } else {
    if (selection.aspect === "basics") {
      content = <BlueprintBasicsProperties query={query} blueprintId={selection.blueprintId} />;
    } else if (selection.aspect === "configuration") {
      content = <BlueprintConfigurationProperties query={query} blueprintId={selection.blueprintId} />;
    } else if (selection.aspect === "globals") {
      content = <BlueprintGlobalsProperties query={query} blueprintId={selection.blueprintId} />;
    } else if (selection.aspect === "element") {
      content = <ElementConfiguration query={query} trayId={trayId!} blueprintId={selection.blueprintId} elementId={selection.elementId}/>
    } else {
      content = (
        <div className="flex items-center justify-center">
          Select something to view its properties.
        </div>
      );
    }
  }
  return (
    <div className="flex h-full flex-col">
      <InputSearch name="search" value={query} onChange={e => setQuery(e.target.value)} onClear={() => setQuery("")} className="m-[-1px] mb-0" inputClassName="rounded-sm" />
      <ScrollArea className="mr-[-4px] flex-auto p-2 pr-3 pt-1" type="auto">
        {content}
      </ScrollArea>
    </div>
  );

}
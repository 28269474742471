import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@ui/resizable";
import { ScrollArea } from "@ui/scroll-area";
import Properties from "../Properties";
import InsertActions from "./InsertActions";
import InsertSidebar from "./InsertSidebar";
import InsertHeader from "./InsertHeader";
import InsertCanvas from "./InsertCanvas";
import CanvasContainer from "../canvas/CanvasContainer";
import InsertToolbar from "./InsertToolbar";

export default function InsertDesigner() {
  return (
    <ResizablePanelGroup direction="horizontal" className="h-full">
      <ResizablePanel className="flex h-full flex-col gap-1 p-1">
        <InsertToolbar />
        <CanvasContainer>
          <InsertCanvas/>
        </CanvasContainer>
      </ResizablePanel>
      <ResizableHandle />
      <ResizablePanel defaultSize={20} className="min-w-[350px] max-w-[500px]">
        <ResizablePanelGroup direction="vertical">
          <ResizablePanel className="relative flex h-full flex-col p-1">
            <InsertActions />
            <InsertHeader />
            <ScrollArea className="flex-auto rounded-b-sm border-x border-b bg-white px-2">
              <InsertSidebar />
            </ScrollArea>
          </ResizablePanel>
          <ResizableHandle />
          <ResizablePanel defaultSize={50} className="min-h-[300px] p-1">
            <div className="h-full rounded-sm border bg-white">
              <Properties />
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </ResizablePanel >
    </ResizablePanelGroup >
  );
}
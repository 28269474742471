import { ElementBlueprint, ContainerElementBlueprint, ElementLayout, StackElementBlueprint, Blueprint, TrayMeasure, Orientation, CanvasElementBlueprint, GridElementBlueprint, PocketElementBlueprint, ElementPlacement, CanvasElementLayout, Vector, TopStacking, TrayPortSegment, TrayPort, ElementMeasure, Bounds, Tray, TrayElement, TrayType, TrayLayoutOffsets } from "@/types";
import { getDefaultElementInstance, getPocketStackingSupport, getTrayLayoutOffsets, measurePocketElement } from "../catalog";
import { Draft } from '@reduxjs/toolkit';
import objectHash from 'object-hash';
import { addTrayMessage, calculateBlueprintHash, calculateTrayHash, printable, roundNumber, roundVector } from "@/utils";
import { toast } from "sonner";

function stackingGap(trayInstance: Draft<Tray>, parent: Readonly<ContainerElementBlueprint>, lastChild: Readonly<ElementBlueprint> | null, currentChild: Readonly<ElementBlueprint>) {
  if(lastChild === null) {
    return 0;
  }
  const lastContainer = lastChild.type === "pocket" ? parent : lastChild;
  const currentContainer = currentChild.type === "pocket" ? parent : currentChild;
  if(lastChild.type === "pocket" && !getPocketStackingSupport(lastChild.pocketType) || currentChild.type === "pocket" && !getPocketStackingSupport(currentChild.pocketType)) {
     // no adjacent stacking
     return 0;
  }
  if(lastContainer.configuration.topStacking + currentContainer.configuration.topStacking == 0) {
    // no adjacent stacking
    return 0;
  } 
  if(lastContainer.configuration.topStacking + currentContainer.configuration.topStacking > 2) {
    // individual stacking adjacent to stacking
    return 2;
  }
  const lastBounds = elementBounds(lastChild, trayInstance.elements[lastChild.id], false);
  const currentBounds = elementBounds(currentChild, trayInstance.elements[currentChild.id], false);
  if(lastContainer.configuration.topStacking !== TopStacking.Disabled && lastBounds.zOffset + lastBounds.z < currentBounds.zOffset + currentBounds.z) {
    // height difference in stacking
    return 2;
  } 
  if(currentContainer.configuration.topStacking !== TopStacking.Disabled && currentBounds.zOffset + currentBounds.z < lastBounds.zOffset + lastBounds.z) {
    // height difference in stacking
    return 2;
  } 
  /*
  if(lastContainer.configuration.topStacking + currentContainer.configuration.topStacking < 2) {
    // no adjacent stacking
    return tray.globals.stackingWall ? 1 : 0;
  } 
  */
  return 0;
}

export function elementPadding(placement: Readonly<ElementPlacement>, layout: Readonly<ElementLayout>) {
  const px = placement.xTotal !== undefined ? placement.xTotal - placement.x : 0;
  const py = placement.yTotal !== undefined ? placement.yTotal - placement.y : 0;
  const left = px * layout.xAlign;
  const front = py * layout.yAlign;
  return {
    xStart: left,
    xEnd: px - left,
    yStart: front,
    yEnd: py - front,
    zStart: placement.zTotal !== undefined ? placement.zTotal - placement.z : 0,
    x: px,
    y: py,
  };
}

export function elementBounds(element: Readonly<ElementBlueprint>, elementInstance: Readonly<TrayElement>, materialized: boolean) {
  if(!materialized || element.type === "pocket") {
    // pocket padding is materialized
    const padding = elementPadding(elementInstance.placement, element.layout);
    return {
      xOffset: elementInstance.placement.xOffset - padding.xStart,
      yOffset: elementInstance.placement.yOffset - padding.yStart,
      zOffset: elementInstance.placement.zOffset - padding.zStart,
      x: elementInstance.placement.xTotal ?? elementInstance.placement.x,
      y: elementInstance.placement.yTotal ?? elementInstance.placement.y,
      z: elementInstance.placement.zTotal ?? elementInstance.placement.z,
    };
  } else {
    return {
      xOffset: elementInstance.placement.xOffset,
      yOffset: elementInstance.placement.yOffset,
      zOffset: elementInstance.placement.zOffset,
      x: elementInstance.placement.x,
      y: elementInstance.placement.y,
      z: elementInstance.placement.z,
    }
  }
}

// #region measure

function arrangeMeasure(measure: TrayMeasure, layout: Readonly<ElementLayout>) {
  // apply layout grow
  if(layout.xGrow === 0) {
    measure.xLimit = measure.xMin;
  } 
  if(layout.yGrow === 0) {
    measure.yLimit = measure.yMin;
  }
  if(layout.zGrow === 0) {
    measure.zLimit = measure.zMin;
  }
  return measure;
}

function roundMeasure(measure: ElementMeasure) {
  measure.xMin = roundNumber(measure.xMin);
  measure.yMin = roundNumber(measure.yMin);
  measure.zMin = roundNumber(measure.zMin);
  if(measure.xLimit !== undefined) {
    measure.xLimit = roundNumber(measure.xLimit);
  }
  if(measure.yLimit !== undefined) {
    measure.yLimit = roundNumber(measure.yLimit);
  }
  if(measure.zLimit !== undefined) {
    measure.zLimit = roundNumber(measure.zLimit);
  }
}

function measureTray(blueprint: Readonly<Blueprint>, tray: Draft<Tray>, firstPass: boolean) {
  const elementBlueprint = blueprint.elements[blueprint.root];
  tray.measure = measureElement(blueprint, elementBlueprint, tray, null, firstPass);
  // round all measures
  roundMeasure(tray.measure);
  for(const measure of Object.values(tray.elements).map(e => e.measure)) {
    roundMeasure(measure);
  }
}

function measureElement(trayBlueprint: Readonly<Blueprint>, elementBlueprint: Readonly<ElementBlueprint>, tray: Draft<Tray>, parentElementBlueprint: Readonly<ContainerElementBlueprint> | null, firstPass: boolean): TrayMeasure {
  const element = tray.elements[elementBlueprint.id];
  switch(elementBlueprint.type) {
    case "stack": return measureStack(trayBlueprint, elementBlueprint, tray, element, firstPass);
    case "canvas": return measureCanvas(trayBlueprint, elementBlueprint, tray, element, firstPass);
    case "grid": return measureGrid(trayBlueprint, elementBlueprint, tray, element, firstPass);
    default: return measurePocket(trayBlueprint, elementBlueprint, element, parentElementBlueprint, firstPass);
  }
}

function measureStack(blueprint: Readonly<Blueprint>, elementBlueprint: Readonly<StackElementBlueprint>, tray: Draft<Tray>, element: Draft<TrayElement>, firstPass: boolean) {
  const m: TrayMeasure = {
    xMin: 0,
    yMin: 0,
    zMin: 0,
  };
  let walls = (elementBlueprint.childs.length - 1) * blueprint.globals.wall;
  let lastChild: Draft<ElementBlueprint> | null = null;
  for(const id of elementBlueprint.childs) {
    // additional walls are needed when stacking changes
    const childBlueprint = blueprint.elements[id];
    if(!firstPass) {
      walls += stackingGap(tray, elementBlueprint, lastChild, childBlueprint) * blueprint.globals.wall;
    }
    const me = measureElement(blueprint, childBlueprint, tray, elementBlueprint, firstPass);
    // use largest z
    m.zMin = Math.max(m.zMin, me.zMin);
    if(elementBlueprint.configuration.orientation === Orientation.Horizontal) {
      // add along x and use largest y
      m.xMin += me.xMin;
      m.yMin = Math.max(m.yMin, me.yMin);
    } else {
      // add along y and use largest x
      m.xMin = Math.max(m.xMin, me.xMin);
      m.yMin += me.yMin;
    }
    lastChild = childBlueprint;
  }
  if(elementBlueprint.configuration.orientation === Orientation.Horizontal) {
    m.xMin += walls;
    if(m.xLimit !== undefined) {
      m.xLimit += walls;
    }
  } else {
    m.yMin += walls;
    if(m.yLimit !== undefined) {
      m.yLimit += walls;
    }
  }
  element.measure = arrangeMeasure(m, elementBlueprint.layout);
  return element.measure;
}

function measureCanvas(blueprint: Readonly<Blueprint>, elementBlueprint: Readonly<CanvasElementBlueprint>, tray: Draft<Tray>, element: Draft<TrayElement>, firstPass: boolean) {
  element.measure = {
    xMin: elementBlueprint.configuration.xMin,
    yMin: elementBlueprint.configuration.yMin,
    zMin: 0, // TODO: use max height of children
    xLimit: elementBlueprint.configuration.xLimit,
    yLimit: elementBlueprint.configuration.yLimit,
  };
  return element.measure;
}

function measureGrid(blueprint: Readonly<Blueprint>, elementBlueprint: Readonly<GridElementBlueprint>, tray: Draft<Tray>, element: Draft<TrayElement>, firstPass: boolean) {
  // TODO: implement
  element.measure = {
    xMin: 0,
    yMin: 0,
    zMin: 0, 
  };
  return element.measure;
}

function measurePocket(blueprint: Readonly<Blueprint>, elementBlueprint: Readonly<PocketElementBlueprint>, element: Draft<TrayElement>, parentElementBLueprint: Readonly<ContainerElementBlueprint> | null, firstPass: boolean) {
  const measure = measurePocketElement(elementBlueprint, blueprint.globals);
  // add stacking height to pocket
  if(parentElementBLueprint !== null && parentElementBLueprint.configuration.topStacking != TopStacking.Disabled) {
    measure.zMin += blueprint.globals.stackingHeight;
  }
  element.measure = arrangeMeasure(measure, elementBlueprint.layout);
  return element.measure;
}

// #endregion

// #region layout

function intersectPorts(first: TrayPortSegment, second: TrayPortSegment) {
  return !(first.xOffset + first.x < second.xOffset || 
           first.xOffset > second.xOffset + second.x ||
           first.yOffset + first.y < second.yOffset || 
           first.yOffset > second.yOffset + second.y);
}

function mergePorts(ports: TrayPortSegment[]) {
  const trayPorts: TrayPort[] = [];
  while(ports.length > 0) {
    // gather port group
    const remaining = ports.splice(0, 1);
    let group: TrayPortSegment[] = [];
    while(remaining.length > 0) {
      const next = remaining.splice(0, 1)[0];
      group.push(next);
      let other = 0;
      while(other < ports.length) {
        if(intersectPorts(next, ports[other])) {
          remaining.push(...ports.splice(other, 1));
        } else {
          other++;
        }
      }
    }
    // analyze port group
    group = group.sort((a, b) => {
      if(a.xOffset == b.xOffset) {
        return a.yOffset - b.yOffset;
      } else {
        return a.xOffset - b.xOffset;
      }
    });
    const xOffset = group[0].xOffset;
    const yOffset = group[0].yOffset;
    const zOffset = group[0].zOffset!;
    for(const port of group) {
      port.xOffset -= xOffset;
      port.yOffset -= yOffset;
      delete port.zOffset;
    }
    trayPorts.push({
      id: trayPorts.length,
      xOffset: xOffset,
      yOffset: yOffset,
      zOffset: zOffset,
      type: objectHash(group),
      segments: group,
    });
  }
  return trayPorts;
}

function calculateLayoutSize(blueprint: Readonly<Blueprint>, tray: Draft<Tray>, offsets: TrayLayoutOffsets)  {
  if(tray.type === TrayType.Preview || tray.size.x === 0 || tray.size.y === 0 || tray.size.z === 0) {
    return {
      x: tray.measure.xMin,
      y: tray.measure.yMin,
      z: tray.measure.zMin,
    };
  } 
  const layoutSize = roundVector({
    x: tray.size.x - offsets.start.x - offsets.extent.x,
    y: tray.size.y - offsets.start.y - offsets.extent.y,
    z: tray.size.z - offsets.start.z - offsets.extent.z,
  });
  if(layoutSize.x < tray.measure.xMin) {
    addTrayMessage(tray, "warning", "x", `The width (X) of the tray was increased to ${printable(tray.measure.xMin)}mm to satisfy its layout.`);
    layoutSize.x = tray.measure.xMin;
  }
  if(layoutSize.y < tray.measure.yMin) {
    addTrayMessage(tray, "warning", "y", `The depth (Y) of the tray was increased to ${printable(tray.measure.yMin)}mm to satisfy its layout.`);
    layoutSize.y = tray.measure.yMin;
  }
  if(layoutSize.z < tray.measure.zMin) {
    addTrayMessage(tray, "warning", "z", `The height (Z) of the tray was increased to ${printable(tray.measure.zMin)}mm to satisfy its layout.`);
    layoutSize.z = tray.measure.zMin;
  }
  if(tray.measure.xLimit !== undefined) {
    if(layoutSize.x > tray.measure.xLimit) {
      addTrayMessage(tray, "warning", "x", `The width (X) of the tray was decreased to ${printable(tray.measure.xLimit + offsets.start.x + offsets.extent.x)}mm to satisfy its layout.`);
      layoutSize.x = tray.measure.xLimit;
    }
  }
  if(tray.measure.yLimit !== undefined) {
    if(layoutSize.y > tray.measure.yLimit) {
      addTrayMessage(tray, "warning", "y", `The depth (y) of the tray was decreased to ${printable(tray.measure.yLimit + offsets.start.y + offsets.extent.y)}mm to satisfy its layout.`);
      layoutSize.y = tray.measure.yLimit;
    }
  }
  if(tray.measure.zLimit !== undefined) {
    if(layoutSize.z > tray.measure.zLimit) {
      addTrayMessage(tray, "warning", "z", `The height (z) of the tray was decreased to ${printable(tray.measure.zLimit + offsets.start.z + offsets.extent.z)}mm to satisfy its layout.`);
      layoutSize.z = tray.measure.zLimit;
    }
  }
  return roundVector(layoutSize);
}

export function layoutTray(blueprint: Readonly<Blueprint>, tray: Draft<Tray>) {
  const ports: TrayPortSegment[] = [];
  const offsets = getTrayLayoutOffsets(blueprint);
  tray.bounds = [];
  let layoutSize: Vector;
  
  // first pass
  measureTray(blueprint, tray, true);
  layoutSize = calculateLayoutSize(blueprint, tray, offsets);
  layoutElement(blueprint, blueprint.elements[blueprint.root], tray, tray.elements[blueprint.root], null, offsets.start, layoutSize!, ports, true);
  // second pass
  measureTray(blueprint, tray, false);
  layoutSize = calculateLayoutSize(blueprint, tray, offsets);
  layoutElement(blueprint, blueprint.elements[blueprint.root], tray, tray.elements[blueprint.root], null, offsets.start, layoutSize!, ports, false);
  // update size
  tray.size = roundVector({
    x: layoutSize.x + offsets.start.x + offsets.extent.x,
    y: layoutSize.y + offsets.start.y + offsets.extent.y,
    z: layoutSize.z + offsets.start.z + offsets.extent.z,
  });
  // update ports
  tray.ports = mergePorts(ports);
  // request new render
  tray.modelHash = calculateTrayHash(tray);
}

function layoutElement(blueprint: Draft<Blueprint>, elementBlueprint: ElementBlueprint, tray: Draft<Tray>, element: Draft<TrayElement>, parentElementBlueprint: ContainerElementBlueprint | null, offset: Readonly<Vector>, size: Readonly<Vector>, ports: TrayPortSegment[], firstPass: boolean) {
  switch(elementBlueprint.type) {
    case "stack": layoutStack(blueprint, elementBlueprint, tray, element, roundVector(offset), roundVector(size), ports, firstPass); break;
    case "canvas": layoutCanvas(blueprint, elementBlueprint, tray, element, roundVector(offset), roundVector(size), ports, firstPass); break;
    case "grid": layoutGrid(blueprint, elementBlueprint, tray, element, roundVector(offset), roundVector(size), ports, firstPass); break;
    default: layoutPocket(blueprint, elementBlueprint, tray, element, roundVector(offset), parentElementBlueprint, roundVector(size), ports, firstPass); break;
  }
}

function placeElement(element: Draft<TrayElement>, layout: ElementLayout, offset: Readonly<Vector>, size: Readonly<Vector>) {
  if(size.x < element.measure.xMin) {
    console.error(`element ${element} layout constraint sx ${size.x} was smaller than mx ${element.measure.xMin}`);
  }
  if(element.measure.xMin == 0 || size.x == element.measure.xMin || element.measure.xLimit === undefined || (element.measure.xLimit !== undefined && size.x <= element.measure.xLimit)) {
    // element fill
    element.placement.x = size.x;
    element.placement.xOffset = offset.x;
    element.placement.xTotal = undefined;
  } else {
    // element must be placed according to alignment
    element.placement.x = element.measure.xLimit ?? element.measure.xMin;
    element.placement.xOffset = offset.x + layout.xAlign * (size.x - element.placement.x);
    // total size is requested size
    element.placement.xTotal = size.x;
  }
  if(element.measure.yMin == 0 || size.y == element.measure.yMin || element.measure.yLimit === undefined || (element.measure.yLimit !== undefined && size.y <= element.measure.yLimit)) {
    // element fill
    element.placement.y = size.y;
    element.placement.yOffset = offset.y;
    element.placement.yTotal = undefined;
  } else {
    // element must be placed according to alignment
    element.placement.y = element.measure.yLimit ?? element.measure.yMin;
    element.placement.yOffset = offset.y + layout.yAlign * (size.y - element.placement.y);
    // total size is requested size
    element.placement.yTotal = size.y;
  }
  if(element.measure.zMin == 0 || size.z == element.measure.zMin || element.measure.zLimit === undefined ||  (element.measure.zLimit !== undefined && size.z <= element.measure.zLimit)) {
    // element fill
    element.placement.z = size.z;
    element.placement.zOffset = offset.z;
    element.placement.zTotal = undefined;
  } else {
    // element must be placed according to alignment
    element.placement.z = element.measure.zLimit ?? element.measure.zMin;
    element.placement.zOffset = offset.z + (size.z - element.placement.z);
    // total size is requested size
    element.placement.zTotal = size.z;
  }
}

function layoutPocket(blueprint: Readonly<Blueprint>, elementBlueprint: Readonly<PocketElementBlueprint>, tray: Draft<Tray>, element: Draft<TrayElement>, offset: Readonly<Vector>, parentElementBlueprint: Readonly<ContainerElementBlueprint> | null, size: Readonly<Vector>, ports: TrayPortSegment[], firstPass: boolean) {
  placeElement(element, elementBlueprint.layout, offset, size);
  if(!firstPass) {
    if(parentElementBlueprint !== null && parentElementBlueprint.configuration.topStacking !== TopStacking.Disabled && getPocketStackingSupport(elementBlueprint.pocketType)) {
      ports.push({
        xOffset: roundNumber(element.placement.xOffset - blueprint.globals.wall),
        yOffset: roundNumber(element.placement.yOffset - blueprint.globals.wall),
        zOffset: roundNumber(element.placement.zOffset + element.placement.z),
        x: roundNumber((element.placement.xTotal ?? element.placement.x) + 2 * blueprint.globals.wall),
        y: roundNumber((element.placement.yTotal ?? element.placement.y) + 2 * blueprint.globals.wall),
      });
    }
    tray.bounds.push({
      x: roundNumber(element.placement.x + 2 * blueprint.globals.wall),
      y: roundNumber(element.placement.y + 2 * blueprint.globals.wall),
      z: roundNumber(element.placement.z + blueprint.globals.wall),
      xOffset: roundNumber(element.placement.xOffset - blueprint.globals.wall),
      yOffset: roundNumber(element.placement.yOffset - blueprint.globals.wall),
      zOffset: roundNumber(element.placement.zOffset - blueprint.globals.wall),
    });
  }
}

function alignCanvasChild(elementBlueprint: Readonly<ElementBlueprint>, element: Draft<TrayElement>, offset: Readonly<Vector>, size: Readonly<Vector>): {
  offset: Vector,
  size: Vector,
} {
  console.log(size);
  return {
    offset: offset,
    size: {
      x: element.measure.xMin,
      y: element.measure.yMin,
      z: element.measure.zMin,
    }
  };
}

function layoutCanvas(blueprint: Readonly<Blueprint>, elementBlueprint: Readonly<ContainerElementBlueprint>, tray: Draft<Tray>, element: Draft<TrayElement>, offset: Readonly<Vector>, size: Readonly<Vector>, ports: TrayPortSegment[], firstPass: boolean) {
  placeElement(element, elementBlueprint.layout, offset, size);
  const childs = elementBlueprint.childs.map(id => blueprint.elements[id]);
  const to: Vector = {
    x: element.placement.xOffset,
    y: element.placement.yOffset,
    z: element.placement.zOffset,
  };
  for(const child of childs) {
    const layout = alignCanvasChild(child, element, to, size);
    layoutElement(blueprint, child, tray, tray.elements[child.id], elementBlueprint, layout.offset, layout.size, ports, firstPass);
  }
}

function layoutGrid(blueprint: Readonly<Blueprint>, elementBlueprint: Readonly<ContainerElementBlueprint>, tray: Draft<Tray>, element: Draft<TrayElement>, offset: Readonly<Vector>, size: Readonly<Vector>, ports: TrayPortSegment[], firstPass: boolean) {
  placeElement(element, elementBlueprint.layout, offset, size);
  const childs = elementBlueprint.childs.map(id => blueprint.elements[id]);
  const to: Vector = {
    x: element.placement.xOffset,
    y: element.placement.yOffset,
    z: element.placement.zOffset,
  };
  for(const child of childs) {
    const childInstance = tray.elements[child.id];
    layoutElement(blueprint, child, tray, tray.elements[child.id], elementBlueprint, to, {
      x: childInstance.measure.xMin,
      y: childInstance.measure.yMin,
      z: childInstance.measure.zMin,
    }, ports, firstPass);
  }
}

export function layoutStack(blueprint: Readonly<Blueprint>, elementBlueprint: Readonly<ContainerElementBlueprint>, tray: Draft<Tray>, element: Draft<TrayElement>, offset: Readonly<Vector>, size: Readonly<Vector>, ports: TrayPortSegment[], firstPass: boolean) {
  placeElement(element, elementBlueprint.layout, offset, size);
  element.placement.fillers = undefined;
  const xDelta = element.placement.x - element.measure.xMin;
  const yDelta = element.placement.y - element.measure.yMin;
  const childs = elementBlueprint.childs.map(id => blueprint.elements[id]);
  let xGrowTotal = 0;
  let yGrowTotal = 0;
  for(const child of childs) {
    xGrowTotal += child.layout.xGrow;
    yGrowTotal += child.layout.yGrow;
  }
  let xOffset = element.placement.xOffset;
  let yOffset = element.placement.yOffset;
  let childSize: Vector;
  let lastChild: Draft<ElementBlueprint> | null = null;
  for(const child of childs) {
    const childInstance = tray.elements[child.id];
    const z = child.layout.zGrow ? element.placement.z : childInstance.measure.zMin;
    if(elementBlueprint.configuration.orientation === Orientation.Vertical) {
      childSize = {
        x: element.placement.x,
        y: childInstance.measure.yMin,
        z: z,
      };
      if(yGrowTotal > 0) {
        childSize.y += child.layout.yGrow / yGrowTotal * yDelta;
      }
    } else {
      childSize = {
        x: childInstance.measure.xMin,
        y: element.placement.y,
        z: z,
      };
      if(xGrowTotal > 0) {
        childSize.x += child.layout.xGrow / xGrowTotal * xDelta;
      }
    }
    // add wall stacking gap to child offset
    let currentStackingGap = 0;
    if(lastChild !== null) {
      // this only works because the child placment height was calculated in pass 0
      currentStackingGap = stackingGap(tray, elementBlueprint, lastChild, child);
      if(elementBlueprint.configuration.orientation === Orientation.Horizontal) {
        xOffset += (currentStackingGap + 1) * blueprint.globals.wall;
      } else {
        yOffset += (currentStackingGap + 1) * blueprint.globals.wall;
      }
    }
    // layout child
    layoutElement(blueprint, child, tray, tray.elements[child.id], elementBlueprint, { x: xOffset, y: yOffset, z: element.placement.zOffset, }, childSize, ports, firstPass);
    // add filler befor child if needed
    if(currentStackingGap > 0) {
      const lastBounds = elementBounds(lastChild!, tray.elements[lastChild!.id], true);
      const currentBounds = elementBounds(child, tray.elements[child.id], true);
      const z = Math.min(lastBounds.z, currentBounds.z);
      if(element.placement.fillers === undefined) {
        element.placement.fillers = [];
      }
      if(elementBlueprint.configuration.orientation === Orientation.Horizontal) {
        const useLast = lastBounds.y < currentBounds.y;
        element.placement.fillers.push({
          xOffset: xOffset - currentStackingGap * blueprint.globals.wall,
          yOffset: useLast ? lastBounds.yOffset : currentBounds.yOffset,
          zOffset: element.placement.zOffset,
          x: blueprint.globals.wall,
          y: useLast ? lastBounds.y : currentBounds.y,
          z: z,
        });
      } else {
        const useLast = lastBounds.x < currentBounds.x;
        element.placement.fillers.push({
          xOffset: useLast ? lastBounds.xOffset : currentBounds.xOffset,
          yOffset: yOffset - currentStackingGap * blueprint.globals.wall,
          zOffset: element.placement.zOffset,
          x: useLast ? lastBounds.x : currentBounds.x,
          y: blueprint.globals.wall,
          z: z,
        });
      }
     
    }
    // move along stack direction
    if(elementBlueprint.configuration.orientation === Orientation.Horizontal) {
      xOffset += childSize.x;
    } else {
      yOffset += childSize.y;
    }
    lastChild = child;
  }
}

export function alignHexagonCutout(blueprint: Draft<Blueprint>, elementBlueprint: PocketElementBlueprint) {
  /*
  const c = tray.configuration as LayoutTrayConfiguration;
  const x = element.placement.xOffset + element.placement.x / 2;
  const y = element.placement.yOffset + element.placement.y / 2;
  console.log(x + y + c.hexCutOrientation);*/
}

// #endregion
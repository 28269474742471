import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from "@ui/resizable";
import BlueprintCanvas from "./BlueprintCanvas";
import Properties from "../Properties";
import BlueprintActions from "./BlueprintActions";
import { ScrollArea } from "@ui/scroll-area";
import BlueprintHeader from "./BlueprintHeader";
import BlueprintElements from "./BlueprintElements";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { TrayType, TrayModel } from "@/types";
import { getBlueprint, getTray, getTraysForBlueprint, selectCurrentBlueprintId, selectFromBlueprint, useAppSelector } from "@/state/store";
import { generateTrayGeometry, inGeometryCache } from "@/workerPool";
import CanvasContainer from "../canvas/CanvasContainer";
import BlueprintToolbar from "./BlueprintToolbar";
import BlueprintMessages from "./BlueprintMessages";

export default function BlueprintDesigner() {
  const debounceTimer = useRef<number | undefined>(undefined);
  const blueprintId = useAppSelector(selectCurrentBlueprintId)!;
  const [trayId, setTrayId] = useState(getTraysForBlueprint(blueprintId).find(i => i.type === TrayType.Preview)!.id);
  const blueprintModelHash = useAppSelector(s => s.model.blueprints[blueprintId].modelHash);
  const trayModelHash = useAppSelector(s => s.model.trays[trayId].modelHash);
  const color = useAppSelector(s => selectFromBlueprint(s, blueprintId, t => t.color));
  const [models, setModels] = useState<TrayModel[]>([]);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
  const [isDebugging, setIsDebugging] = useState<boolean>(false);
  useLayoutEffect(() => {
    setTrayId(getTraysForBlueprint(blueprintId).find(i => i.type === TrayType.Preview)!.id);
  }, [ blueprintId ]);
  // update model
  const updateModels = useCallback(() => {
    setIsDebouncing(false);
    setIsGenerating(true);
    generateTrayGeometry(trayId, isDebugging).then(result => {
      setModels(result.map(g => ({
        ...g,
        color: getBlueprint(blueprintId).color,
        trayId: blueprintId,
      })));
      setIsGenerating(false);
    }, error => {
      setIsGenerating(false);
    });
  }, [ trayId, isDebugging ]);
  // debounce render requests
  useEffect(() => {
    setIsDebouncing(true);
    clearTimeout(debounceTimer.current);
    if(inGeometryCache(blueprintId, isDebugging)) {
      updateModels();
    } else {
      debounceTimer.current = setTimeout(updateModels, 800);
    }
  }, [ blueprintId, blueprintModelHash, trayModelHash ]);
  useEffect(() => {
    clearTimeout(debounceTimer.current);
    updateModels();
  }, [ trayId, isDebugging ]);
  // update color of existing models
  useEffect(() => {
    setModels([
      ...models.map(m => ({
        ...m,
        color: color,
      }))
    ]);
  }, [ color ]);
  return (
    <ResizablePanelGroup direction="horizontal" autoSaveId="tray-panels-layout" storage={localStorage} className="h-full">
      <ResizablePanel className="flex flex-col gap-1 p-1">
        <BlueprintToolbar/>
        <CanvasContainer>
          <BlueprintCanvas isDebouncing={isDebouncing} isGenerating={isGenerating} isDebugging={isDebugging} setIsDebugging={debug => setIsDebugging(debug)} models={models} blueprintId={blueprintId} trayId={trayId} setTrayId={id => setTrayId(id)}/>
        </CanvasContainer>
      </ResizablePanel>
      <ResizableHandle />
      <ResizablePanel defaultSize={20} className="min-w-[350px] max-w-[500px]">
        <ResizablePanelGroup direction="vertical">
          <ResizablePanel className="relative flex h-full flex-col p-1">
              <BlueprintActions blueprintId={blueprintId} models={models} trayId={trayId}/>
              <BlueprintHeader blueprintId={blueprintId}/>
              <ScrollArea className="flex-auto rounded-b-sm border-x border-b bg-white px-2">
                <BlueprintMessages blueprintId={blueprintId} trayId={trayId}/>
                <BlueprintElements blueprintId={blueprintId}/>
              </ScrollArea>
          </ResizablePanel>
          <ResizableHandle />
          <ResizablePanel defaultSize={50} className="min-h-[300px] p-1">
            <div className="h-full rounded-sm border bg-white">
              <Properties trayId={trayId}/>
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </ResizablePanel>
    </ResizablePanelGroup>
  );
}
import { DbInsert } from "../../state/api";

export default function InsertItem({insert} : {insert: DbInsert}) {
  return (
    <div className="flex">
      <img src={insert.image} className="size-6 rounded-sm"/>
      <div>
        {insert.name}
      </div>
      <div className="text-secondary-foreground">
        {insert.gameName}
      </div>
    </div>
  );
}
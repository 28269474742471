import { Button } from "@ui/button";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { Avatar, AvatarFallback } from "@ui/avatar";
import { ChevronDownIcon, CommitIcon, EnterIcon, PersonIcon } from "@radix-ui/react-icons";
import { Dialog, DialogContent, DialogTrigger } from "@ui/dialog";
import NavigationLogin from "./NavigationLogin";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@ui/dropdown-menu"
import { useEffect, useState } from "react";
import { login, logout } from "@/state/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLazyLoginQuery } from "@/state/api";

export default function NavigationMenuLogin() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [triggerLogin, loginStatus] = useLazyLoginQuery();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(s => s.auth);
  const [token, setToken] = useState(localStorage.getItem("token") ?? undefined);
  const [visible, setVisible] = useState(searchParams.get("login") === "true" && token === undefined);
  useEffect(() => {
    if(token !== undefined) {
      triggerLogin({
        token: token,
      }).then(r => {
        if(r.isSuccess) {
          dispatch(login({
            token: token,
            user: r.data,
          }));
        }
      });
    }
  }, [token]);
  if(loginStatus.isFetching) {
    return (
      <div className="flex h-8 items-center gap-2 rounded-sm border px-2">
        <CommitIcon className="animate-spin"/>
        Logging you in...
      </div>
    );
  } else if (auth.user !== undefined) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="h-8 px-2">
            <Avatar>
              <AvatarFallback>
                {auth.user?.name[0].toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <ChevronDownIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          <div className="flex items-center gap-2 p-2">
          <Avatar>
              <AvatarFallback>
                {auth.user?.name[0]}
              </AvatarFallback>
            </Avatar>
            {auth.user?.name}
          </div>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              Profile
            </DropdownMenuItem>
            <DropdownMenuItem>
              My inserts
            </DropdownMenuItem>
            <DropdownMenuItem>
              Shared inserts
            </DropdownMenuItem>
            <DropdownMenuItem>
              Liked inserts
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              Settings
            </DropdownMenuItem>
          <DropdownMenuItem onClick={() => {
            dispatch(logout());
            navigate("/");
          }}>
            Log out
          </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  } else {
    return (
      <>
        <Dialog open={visible} onOpenChange={setVisible}>
          <DialogTrigger asChild>
            <Button variant="outline" className="px-2 underline">
              <EnterIcon />
              Log in
            </Button>
          </DialogTrigger>
          <DialogContent className="w-96">
            <NavigationLogin tokenReceived={t => {
              setToken(t);
              setVisible(false);
            }}/>
          </DialogContent>
        </Dialog>
        <Button variant="outline" className="px-2">
          <PersonIcon />
          Sign up
        </Button>
      </>
    );
  }
}
import { useEffect } from "react";
import InsertDesigner from "./insert/InsertDesigner";
import BlueprintDesigner from "./blueprint/BlueprintDesigner";
import { selectCurrentBlueprintId, useAppDispatch, useAppSelector } from "@/state/store";
import { restoreModel } from "../../state/model";
import { useSearchParams } from "react-router-dom";
import { useLazyGetTrayQuery } from "@/state/api";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@ui/dialog";
import { importBlueprint } from "@/utils";
import { getDefaultInsert, getDefaultTray } from "@/catalog";
import { Model } from "@/types";
import { Toaster } from "@ui/sonner";

export default function Designer() {
  const dispatch = useAppDispatch();
  const scope = useAppSelector(s => s.model.selection.scope);
  const [ searchParams ] = useSearchParams();
  const [ loadSharedTray, loadSharedTrayStatus ] = useLazyGetTrayQuery();
  useEffect(() => {
    const trayId = searchParams.get("tray");
    if(trayId !== null && loadSharedTrayStatus.isUninitialized) {
      loadSharedTray({
        id: trayId,
      }).then(result => {
        if(result.isSuccess) {
          const blueprint = importBlueprint(result.data.content);
          if(blueprint !== null) {
            blueprint.library = {
              libraryId: trayId,
              version: result.data.latestVersion,
              timestamp: result.data.timestamp,
              isIsolated: true,
            };
            const model: Model = {
              insert: getDefaultInsert({
                name: result.data.name,
                globals: blueprint.globals,
                trays: [ 0 ],
              }),
              blueprints: {
                [blueprint.id]: blueprint,
              },
              trays: { 
                0: getDefaultTray({
                  id: 0,
                  blueprintId: blueprint.id,
                }),
              },
              selection: {
                scope: "blueprint",
                aspect: "configuration",
                blueprintId: blueprint.id,
              },
              history: {
                index: 0,
                records: [],
              }
            };
            console.log("restore model");
            dispatch(restoreModel(model));
          }
        }
      });
    }
  }, [ searchParams ]);
  return (
    <>
      <Toaster position="bottom-left" theme="light"/>
      {scope === "blueprint" ? (
        <BlueprintDesigner/>
      ) : (
        <InsertDesigner/>
      )}
      {loadSharedTrayStatus.isFetching && (
        <Dialog>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                Loading tray from library...
              </DialogTitle>
            </DialogHeader>
            <p>
              This will only take a second!
            </p>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
import { Badge } from "@ui/badge";
import { selectFromCurrentBlueprint, useAppDispatch, useAppSelector } from "@/state/store";
import { CrossCircledIcon, ExclamationTriangleIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import { Button } from "@ui/button";
import { clearTrayMessages } from "@/state/model";
import { getTrayName } from "@/utils";

export default function BlueprintMessages({blueprintId, trayId} : {blueprintId: number, trayId: number}) {
  const dispatch = useAppDispatch();
  const blueprintName = useAppSelector(s => s.model.blueprints[blueprintId].name);
  const trayName = useAppSelector(s => s.model.trays[trayId].name);
  const name = getTrayName(blueprintName, trayName);
  const messages = useAppSelector(s => s.model.trays[trayId].messages);
  if(messages.length == 0) {
    return <></>;
  }
  return (
    <div className="mb-2 flex flex-col">
      <div className="rounded-t-lg border-x border-t bg-slate-50 p-2 text-sm">
        These changes in the tray <span className="font-semibold">{name}</span> have affected the insert layout:
        {messages.map(m => <div key={messages.indexOf(m)} className="flex items-center gap-2">
          {m.type === "warning" ? <ExclamationTriangleIcon className="text-yellow-500"/> : m.type === "error" ? <CrossCircledIcon/> : <InfoCircledIcon/>}
          <div>{m.content}</div>
        </div>)}
      </div>
      <Button variant="outline" className="rounded-t-none" onClick={() => dispatch(clearTrayMessages({
        tray: trayId,
      }))}>
        Acknowledge
        </Button>
    </div>
  );
}
/* eslint-disable react/no-unknown-property */
import { GeometryEdge, GeometryFace, Blueprint, TrayModel, Vector, TrayType } from "@/types"
import { ReplicadShapes } from "../canvas/ReplicadShapes";
import { useLayoutEffect, useMemo, useState } from "react";
import { Crosshair2Icon, CursorArrowIcon, GearIcon, RocketIcon, RulerSquareIcon } from "@radix-ui/react-icons";
import Scene from "@/pages/designer/canvas/Scene";
import { Switch } from "@ui/switch";
import { Label } from "@ui/label";
import { Button } from "@ui/button";
import CanvasEdgeInfo from "../canvas/CanvasEdgeInfo";
import CanvasFaceInfo from "../canvas/CanvasFaceInfo";
import CanvasPointInfo from "../canvas/CanvasPointInfo";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@ui/popover"
import { Box, Grid, Sphere, Text } from "@react-three/drei";
import BoxInfo from "../canvas/BoxInfo";
import BoxFootprint from "../canvas/BoxFootprint";
import Skeleton from "../canvas/Skeleton";
import { getTraysForBlueprint, selectCurrentElement, selectFromBlueprint, selectTraysForBlueprint, useAppDispatch, useAppSelector } from "@/state/store";
import { configurationKeyName } from "@/catalog";
import { getBlueprintContainerElements, getBlueprintProcketElements as getBlueprintPocketElements, getTrayName } from "@/utils";
import { clearTrayMessages, select } from "@/state/model";
import Canvas from "../canvas/Canvas";
import { useCanvasSettings, useSetCanvasSettings } from "../canvas/CanvasContainer";
import roboto from '../../../../public/fonts/RobotoMono-Regular.ttf?url'
import { Select, SelectContent, SelectItem, SelectTrigger } from "@ui/select";
import BlueprintCanvasTray from "./BlueprintCanvasTray";
import DesignerMessagesBar from "../DesignerMessagesBar";

export enum CanvasMode {
  Select,
  Measure,
}

export interface FaceSelection {
  type: "face",
  shapeName: string,
  index: number,
  face: GeometryFace,
}

export interface EdgeSelection {
  type: "edge",
  shapeName: string,
  index: number,
  edge: GeometryEdge,
}

export interface PointSelection {
  type: "point",
  point: Vector,
}

export type Selection = FaceSelection | EdgeSelection | PointSelection;

function selectionDiffers(a: Selection, b: Selection) {
  if (a.type == "edge" && b.type == "edge") {
    if (a.shapeName === b.shapeName && a.index === b.index) {
      return false;
    }
  }
  if (a.type == "face" && b.type == "face") {
    if (a.shapeName === b.shapeName && a.index == b.index) {
      return false;
    }
  }
  if (a.type == "point" && b.type == "point") {
    if (a.point.x === b.point.x && a.point.y === b.point.y && a.point.z === b.point.z) {
      return false;
    }
  }
  return true;
}

export default function BlueprintCanvas({ blueprintId, trayId, setTrayId, models, isDebouncing, isGenerating, isDebugging, setIsDebugging }: {
  blueprintId: number,
  trayId: number,
  setTrayId: (id: number) => void,
  models: TrayModel[],
  isDebouncing: boolean,
  isGenerating: boolean,
  isDebugging: boolean,
  setIsDebugging: (debug: boolean) => void,
}) {
  const [selections, setSelections] = useState<Selection[]>([]);
  const [mode, setMode] = useState<CanvasMode>(CanvasMode.Select);
  const [selectEdges, setSelectEdges] = useState(true);
  const [selectFaces, setSelectFaces] = useState(true);
  const [selectPoints, setSelectPoints] = useState(false);
  const [selectPockets, setSelectPockets] = useState(true);
  const [selectContainers, setSelectContainers] = useState(false);
  const selectedElement = useAppSelector(selectCurrentElement);
  const selectedElementInstance = useAppSelector(s => selectedElement === null ? null : s.model.trays[trayId].elements[selectedElement.id]);
  const tray = useAppSelector(s => s.model.trays[trayId]);
  const blueprint = useAppSelector(s => s.model.blueprints[blueprintId]);
  const blueprintTrays = useAppSelector(s => selectTraysForBlueprint(s, blueprint.id));
  const [visibleModels, setVisibleModels] = useState<string[]>([]);
  const blueprintRoot = useAppSelector(s => s.model.blueprints[blueprintId].root);
  const pockets = useMemo(() => getBlueprintPocketElements(tray, blueprint.elements, blueprintRoot), [tray, blueprint.elements, blueprintRoot]);
  const containers = useMemo(() => getBlueprintContainerElements(tray, blueprint.elements, blueprintRoot), [tray, blueprint.elements, blueprintRoot]);
  const dispatch = useAppDispatch();
  const settings = useCanvasSettings();
  const setSettings = useSetCanvasSettings();
  let infoCount = 0;
  let warningCount = 0;
  let errorCount = 0;
  for(const tray of blueprintTrays) {
    for(const message of tray.messages) {
      if(message.type === "info") {
        infoCount++;
      } else if(message.type === "warning") {
        warningCount++;
      } else {
        errorCount++;
      }
    }
  }
  useLayoutEffect(() => {
    setSelections([]);
    const remainingModels = visibleModels.filter(v => models.some(r => r.name == v));
    if (remainingModels.length == 0) {
      if (models.some(r => r.name == "tray")) {
        remainingModels.push("tray");
      } else if (models.length > 0) {
        remainingModels.push(models[0].name);
      }
    }
    setVisibleModels(remainingModels);
  }, [models]);
  return (
    <>
      <Canvas>
        <Grid cellSize={1} cellThickness={0.6} sectionSize={10} cellColor="#F0F0F0" sectionColor="#F0F0F0" infiniteGrid={true} position={[0, 0, -0.1]} rotation={[Math.PI / 2, 0, 0]} fadeDistance={2000} fadeStrength={6} followCamera={true} />
        <Scene panHorizontal={false}>
          {!isGenerating && models !== null && (
            <>
              <ReplicadShapes clipConstant={5} clipDirection={{ x: 0, y: 1, z: 0 }} wireframe={settings.wireframe} models={models.filter(m => visibleModels.includes(m.name))} selections={selections} disableLayout={true} onSelect={s => {
                if (mode === CanvasMode.Measure && ((selectEdges && s.type == "edge") || (selectFaces && s.type == "face"))) {
                  if (selections.length == 0) {
                    setSelections([s]);
                  } else if (selections.length == 1) {
                    if (selectionDiffers(selections[0], s)) {
                      setSelections([selections[0], s]);
                    } else {
                      setSelections([]);
                    }
                  } else {
                    if (selectionDiffers(selections[1], s)) {
                      setSelections([selections[1], s]);
                    } else {
                      setSelections([selections[1]]);
                    }
                  }
                }
              }} />
              {/*settings.showPorts && (
                <group>
                  {tray.ports.map(p => (
                    <mesh key={p.type} position={[p.xOffset, p.yOffset, p.zOffset + 0.02]} scale={[p.x, p.y, 1]}>
                      <planeGeometry />
                      <planeGeometry />
                      <meshMatcapMaterial color="green" side={2} transparent={true} opacity={0.5} />
                    </mesh>
                  ))}
                </group>
                  )*/}
              {isDebugging && (
                <BoxInfo color="#cbd5e1" position={{ x: 0, y: 0, z: 0 }} size={tray.size} showCoordinates={true} />
              )}
              {!isDebouncing && mode === CanvasMode.Select && selectedElement !== null && selectedElementInstance !== null && (
                <>
                  <Text color="#ffffff" position={[selectedElementInstance.placement.xOffset + 1, selectedElementInstance.placement.yOffset + selectedElementInstance.placement.y - 1, selectedElementInstance.placement.zOffset + selectedElementInstance.placement.z]} anchorX="left" anchorY="top" fontSize={2.5} font={roboto}>
                    {selectedElement.type === "pocket" ? selectedElement.name : configurationKeyName(selectedElement.type)}
                  </Text>
                  <BoxInfo color="#cbd5e1" position={{ x: selectedElementInstance.placement.xOffset, y: selectedElementInstance.placement.yOffset, z: selectedElementInstance.placement.zOffset }} size={{ x: selectedElementInstance.placement.x, y: selectedElementInstance.placement.y, z: selectedElementInstance.placement.z }} showCoordinates={false} />
                </>
              )}
            </>
          )}
          {(isGenerating || isDebouncing) && (
            <Skeleton size={tray.size} pockets={pockets} />
          )}
          <BoxFootprint position={{ x: 0, y: 0, z: 0 }} size={tray.size} />
          <Sphere position={[0, 0, 0]} material-color="#cbd5e1" scale={0.2} />
          {mode === CanvasMode.Select && selectPockets && pockets.map(p => (
            <Box key={p.blueprint.id} userData={{
              "pocket": p.blueprint.id,
            }} position={[p.element.placement.xOffset + p.element.placement.x / 2, p.element.placement.yOffset + p.element.placement.y / 2, p.element.placement.zOffset + p.element.placement.z / 2]} scale={[p.element.placement.x, p.element.placement.y, p.element.placement.z]} onClick={e => {
              if (e.intersections.find(i => i.eventObject.userData["pocket"] !== undefined)?.eventObject.userData["pocket"] === p.blueprint.id) {
                dispatch(select({
                  scope: "blueprint",
                  aspect: "element",
                  blueprintId: blueprintId,
                  elementId: p.blueprint.id,
                }));
              }
            }}>
              <meshBasicMaterial transparent={true} opacity={0} depthTest={false} />
            </Box>
          ))}
          {mode === CanvasMode.Select && selectContainers && containers.map(c => (
            <Box key={c.id} userData={{
              "container": c.id,
            }} position={[c.element.placement.xOffset + c.element.placement.x / 2, c.element.placement.yOffset + c.element.placement.y / 2, c.element.placement.zOffset + c.element.placement.z / 2]} scale={[c.element.placement.x, c.element.placement.y, c.element.placement.z]} onClick={e => {
              if (e.intersections.find(i => i.eventObject.userData["container"] !== undefined)?.eventObject.userData["container"] === c.id) {
                dispatch(select({
                  scope: "blueprint",
                  aspect: "element",
                  blueprintId: blueprintId,
                  elementId: c.id,
                }));
              }
            }}>
              <meshBasicMaterial transparent={true} opacity={0} depthTest={false} />
            </Box>
          ))}
        </Scene>
      </Canvas>
      <div className="absolute bottom-1 left-1 z-50 flex select-none flex-col gap-1">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" className="w-28 justify-start">
              <GearIcon />
              Settings
            </Button>
          </PopoverTrigger>
          <PopoverContent align="start" className="w-auto p-2" >
            <div className="flex select-none flex-col gap-2">
              <div className="flex items-center space-x-2">
                <Switch id="center" checked={settings.centerCamera} onCheckedChange={e => setSettings({
                  ...settings,
                  centerCamera: e,
                })} />
                <Label htmlFor="center">Center</Label>
              </div>
              <div className="flex items-center space-x-2">
                <Switch id="center" checked={settings.showGizmo} onCheckedChange={e => setSettings({
                  ...settings,
                  showGizmo: e,
                })} />
                <Label htmlFor="center">Show gizmo</Label>
              </div>
              <div className="flex items-center space-x-2">
                <Switch id="center" checked={settings.showLines} onCheckedChange={e => setSettings({
                  ...settings,
                  showLines: e,
                })} />
                <Label htmlFor="center">Show edges</Label>
              </div>
              <div className="flex items-center space-x-2">
                <Switch id="center" checked={settings.wireframe} onCheckedChange={e => setSettings({
                  ...settings,
                  wireframe: e,
                })} />
                <Label htmlFor="center">Show wireframe</Label>
              </div>
              <div className="flex items-center space-x-2">
                <Switch id="ports" checked={settings.showPorts} onCheckedChange={e => setSettings({
                  ...settings,
                  showPorts: e,
                })} />
                <Label htmlFor="ports">Show stacking ports</Label>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
      <div className="absolute right-1 top-1 z-50 flex select-none flex-col items-end gap-1">
        <div className="flex flex-col gap-2 rounded-sm border bg-white/80 p-2">
          <div className="my-[-8px] flex items-center justify-between gap-2">
            <Label htmlFor="instance" className="py-2">Instance</Label>
            <DesignerMessagesBar infoCount={infoCount} warningCount={warningCount} errorCount={errorCount}/>
          </div>
          <Select onValueChange={v => setTrayId(parseInt(v))}>
            <SelectTrigger id="instance" className="h-fit min-w-48 bg-white">
              <BlueprintCanvasTray tray={tray} blueprint={blueprint}/>
            </SelectTrigger>
            <SelectContent>
              {blueprintTrays.map(t => <SelectItem key={t.id} value={t.id.toString()}>
                <BlueprintCanvasTray key={t.id} tray={t} blueprint={blueprint}/>
              </SelectItem>)}
            </SelectContent>
          </Select>
          {infoCount + warningCount + errorCount > 0 && (
            <Button variant="outline" onClick={() => dispatch(clearTrayMessages({
              blueprint: blueprintId,
            }))}>
              Acknowledge all
            </Button>
          )}
        </div>
        <div className="flex flex-col rounded-sm border bg-white/80">
          <div className="flex items-center gap-2 p-2">
            <Switch id="debug" checked={isDebugging} onCheckedChange={setIsDebugging} />
            <Label htmlFor="debug">Debug</Label>
          </div>
          {models.length > 1 && (
            <>
              {models.map(m => (
                <div key={m.name} className="flex items-center border-t ">
                  <Button onClick={() => setVisibleModels([m.name])} variant="ghost" className="size-8 rounded-none p-0">
                    <Crosshair2Icon />
                  </Button>
                  <Button onClick={() => {
                    if (visibleModels.includes(m.name)) {
                      setVisibleModels(visibleModels.filter(v => v !== m.name))
                    } else {
                      setVisibleModels([...visibleModels, m.name]);
                    }
                  }} variant="outline" className="h-8 flex-auto justify-start rounded-none border-none px-2 py-0">
                    <div className={visibleModels.includes(m.name) ? "text-primary underline" : ""}>
                      {m.name}
                    </div>
                  </Button>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="absolute left-1 top-1 z-50 flex select-none items-start gap-1">
        <div className="flex w-32 flex-col gap-1">
          <Button variant="outline" disabled={mode === CanvasMode.Select} className="z-30 justify-start disabled:border-primary disabled:opacity-100" onClick={() => {
            setMode(CanvasMode.Select);
            setSelections([]);
          }}>
            <CursorArrowIcon />
            Select
          </Button>
          {mode == CanvasMode.Select && (
            <div className="z-20 flex flex-col gap-1 rounded-sm border bg-white/80 p-2 pt-3" style={{
              marginTop: -8,
            }}>
              <div className="flex items-center gap-2">
                <Switch id="selectPockets" checked={selectPockets} onCheckedChange={c => {
                  setSelectPockets(c);
                  setSelectContainers(!c);
                }} />
                <Label htmlFor="selectPockets">Pockets</Label>
              </div>
              <div className="flex items-center gap-2">
                <Switch id="selectContainers" checked={selectContainers} onCheckedChange={c => {
                  setSelectPockets(!c);
                  setSelectContainers(c);
                }} />
                <Label htmlFor="selectContainers">Containers</Label>
              </div>
            </div>
          )}
          <Button variant="outline" disabled={mode == CanvasMode.Measure} className="z-30 justify-start disabled:border-primary disabled:opacity-100" onClick={() => setMode(CanvasMode.Measure)}>
            <RulerSquareIcon />
            Measure
          </Button>
          {mode == CanvasMode.Measure && (
            <div className="z-20 flex flex-col gap-1 rounded-sm border bg-white/80 p-2 pt-3" style={{
              marginTop: -8,
            }}>
              <div className="flex items-center gap-2">
                <Switch id="selectEdges" checked={selectEdges} onCheckedChange={c => {
                  setSelectEdges(c);
                  if (c) {
                    setSelectPoints(false);
                    setSelections(selections.filter(s => s.type !== "point"));
                  } else {
                    setSelections(selections.filter(s => s.type !== "edge"));
                  }
                }} />
                <Label htmlFor="selectEdges">Edges</Label>
              </div>
              <div className="flex items-center gap-2">
                <Switch id="selectFaces" checked={selectFaces} onCheckedChange={c => {
                  setSelectFaces(c);
                  if (c) {
                    setSelectPoints(false);
                    setSelections(selections.filter(s => s.type !== "point"));
                  } else {
                    setSelections(selections.filter(s => s.type !== "face"));
                  }
                }} />
                <Label htmlFor="selectFaces">Faces</Label>
              </div>
              <hr className="my-1" />
              <div className="flex items-center gap-2">
                <Switch id="selectPoints" checked={selectPoints} onCheckedChange={c => {
                  setSelectPoints(c);
                  if (c) {
                    setSelectEdges(false);
                    setSelectFaces(false);
                    setSelections(selections.filter(s => s.type === "point"));
                  } else {
                    setSelections(selections.filter(s => s.type !== "face"));
                  }
                }} />
                <Label htmlFor="selectFaces">Points</Label>
              </div>
            </div>
          )}
        </div>
        {selections.length > 0 && (
          <div className="flex flex-col gap-1">
            {selections[0].type == "edge" ? (
              <CanvasEdgeInfo edge={selections[0].edge} designator={mode === CanvasMode.Measure ? "A" : undefined} />
            ) : selections[0].type == "face" ? (
              <CanvasFaceInfo face={selections[0].face} designator={mode === CanvasMode.Measure ? "B" : undefined} />
            ) : (
              <CanvasPointInfo point={selections[0].point} designator={mode === CanvasMode.Measure ? "B" : undefined} />
            )}
          </div>
        )}
        {selections.length > 1 && (
          <div className="flex flex-col gap-1">
            {selections[1].type == "edge" ? (
              <CanvasEdgeInfo edge={selections[1].edge} designator={mode === CanvasMode.Measure ? "B" : undefined} />
            ) : selections[1].type == "face" ? (
              <CanvasFaceInfo face={selections[1].face} designator={mode === CanvasMode.Measure ? "B" : undefined} />
            ) : (
              <CanvasPointInfo point={selections[1].point} designator={mode === CanvasMode.Measure ? "B" : undefined} />
            )}
          </div>
        )}
      </div>
      {isGenerating && (
        <div className="absolute inset-x-0 top-1 flex select-none justify-center">
          <div className="flex items-center gap-2 rounded-sm border border-primary bg-white px-4 py-2 text-secondary-foreground">
            <RocketIcon className="size-6 text-primary" />
            Updating model...
          </div>
        </div>
      )}
    </>
  );
}
import { Outlet } from "react-router";
import NavigationMenu from "@/pages/navigation/NavigationMenu";

export default function ExploreLayout() {
  return (
    <div className="relative h-screen w-full bg-slate-50">
      <div className="absolute inset-x-0 flex h-12 justify-center bg-white shadow-md">
        <div className="w-full max-w-[1600px] px-2">
          <NavigationMenu/>
        </div>
      </div>
      <main className="absolute inset-x-0 bottom-0 top-12 flex justify-center">
        <div className="size-full max-w-[1600px] px-2">
          <Outlet />
        </div>
      </main>
    </div>
  );
}